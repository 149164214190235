/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
a {
  color: $activeColor;
  transition: color .6s;
  &:hover {
  }
  &:active,
  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
select,
textarea {
  padding: 13px 35px;
  height: 50px;
  font-family: $mainFont;
  font-size: 15px;
  border-radius: 25px;
  transition: padding .5s;
  color: #000;
  border: 1px solid #e5e5e5;
  outline: none;
  background: #fff;
  &:focus {
    border: 1px solid #d4d4d4;
    padding-left: 30px;
    padding-right: 40px;
  }
}
input[type="submit"] {
  @extend .btn;
  outline: none;
}

.single-entry {
  ul > li {
    margin-left: 18px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}
img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}
img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.alignright {
  float: right;
}
.alignleft {
  float: left;
}
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.social-links {
  list-style-type: none;
  li {
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    a {
      border-radius: 50%;
      font-size: 24px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      display: inline-block;
    }
  }
}

.t-hide {
  display: none;
}

.clear {
  clear: both;
}

.btn {
  height: 50px;
  //color: white;
  //font-family: $mainFont;
  font-size: 15px;
  border-radius: 25px;
  transition: all .5s;
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
  padding: 0 20px;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.btn-blue {
  @extend .btn;
  background-color: $activeColor;
  color: #fff;

  &:hover {
    background-color: darken($activeColor, 10%);
    color: #fff;
  }
}

.btn-gray {
  @extend .btn;
  border: 2px solid #f0f0f0;
  color: #000;
  font-weight: 700;
  line-height: 46px;
  &:hover {
    background-color: $activeColor;
    color: #fff;
  }
}

.btn-white {
  @extend .btn;
  background-color: #fff;
  font-weight: 700;
  line-height: 46px;
  border: none;
}
.lang-switch {
  display: inline-block;
  padding-right: 32px;
  border-right: 1px solid #ebf5f8;
  padding-left: 20px;
  li {
    list-style-type: none;
    display: inline-block;
    position: relative;
    a {
      width: 50px;
      height: 50px;
      padding-top: 12px;
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;
      color: #000;
      border: 2px solid #e3e3e3;
      background: #fff;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      a {
        //background-color: $activeColor;
      }
    }
    &.current-lang {
      z-index: 2;
      a {
        background-color: $activeColor;
        border: 2px solid $activeColor;
        color: #fff;
      }
    }
    &:last-child {
      margin-left: -12px;
    }
  }
}

.menu-btn-wrap {
  display: inline-block;
  padding-left: 32px;
  font-weight: 700;
  text-transform: uppercase;
  .mobile-nav-btn {
    cursor: pointer;
    font-size: 50px;
    height: 20px;
    vertical-align: middle;
    position: relative;
    z-index: 11;
    display: inline-block;
    width: 35px;
    margin-left: 16px;
    [class^="line"] {
      display: block;
      height: 4px;
      margin-bottom: 5px;
      width: 100%;
      background-color: $activeColor;
      transition: all .3s ease;
      border-radius: 3px;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.c-table {
  display: table;
}
.c-row {
  display: table-row;
}
.c-cell {
  display: table-cell;
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
#main-nav {
  position: fixed;
  //top: 90px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
  width: 100%;
  transition: all .5s;
  height: 0;
  overflow: hidden;
  ul.lang-switch {
    margin-top: 0;
    border-right: none;
    padding: 30px;
    position: absolute;
    display: none;
    top: 0;
    left: 0;
  }
  .main-mob-menu {
    display: none;
    list-style-type: none;
    text-align: center;
    margin-top: 100px;
    text-transform: uppercase;
    font-size: 24px;
    li {
      margin-bottom: 20px;
    }
    a {
      transition: all .5s;
      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  .social-links-wrap {
    position: absolute;
    bottom: 0;
    display: none;
    margin-bottom: 100px;
    padding-left: 0;
    text-align: center;
    width: 100%;
    .social-links li a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 24px;
    }
  }
}
.menu-opened {
  .mobile-nav-btn {
    .line1 {
      transform-origin: 0 0;
      transform: rotate(45deg) translate(-2px, -5px);
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      transform-origin: 0 0;
      transform: rotate(-45deg) translate(-2px, 1px);
    }
  }
  .mobile-menu-wrap {
    transform: translateX(0px);
  }

  #main-nav {
    height: 100%;
    ul.main-mob-menu {
      display: block;
    }
  }
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
.info-noprofit {
  height: 41px;
  background-color: #7ad5ff;
  text-align: center;
  color: #fff;
  padding-top: 8px;
  position: relative;
  font-size: 14px;
  .close-btn {
    position: absolute;
    right: 0;
    margin-right: 25px;
    margin-top: 4px;
    cursor: pointer;
  }
  &.t-hide {
    display: none;
  }
}

.top-header-wrap {
  background: url('../images/lblue-white-bg.svg') repeat-y;
  background-size: 100%;
  border-bottom: 1px solid #f6f6f6;
  height: 90px;
  top: -90px;
  .header-right {
    background: #fff;
  }
  .social-links-wrap {
    padding: 16px 0 0 0;
    height: 89px;
  }
  .social-links {
    li {
      background: #fff;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .header-elements {
    background-color: #fff;
    text-align: right;
    padding: 16px 0 0 0;
    height: 89px;
    .donate {
      display: inline-block;
      padding-right: 32px;
      border-right: 1px solid #ebf5f8;
      .donate-icon {
        display: none;
      }
    }
  }
  .mobile-site-logo {
    display: none;
  }
}
.fixed-header,
.menu-opened {
  .top-header-wrap {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 3;
    top: 0;
    transition: all 1s;
  }
  .site-main {
    margin-top: 90px;
  }
}
.show-top-info {
  #main-nav {
    top: 131px;
  }
}
&.hide-top-info {
  #main-nav {
    top: 90px;
  }
}
.fixed-header {
  #main-nav {
    top: 90px;
  }
}
.menu-opened {
  .top-header-wrap {
    top: 0;
    transition: none;
  }
  &.show-top-info {
    .top-header-wrap {
      top: 41px;
    }
    #main-nav {
      //top: 131px;
    }
    &.fixed-header {
      .top-header-wrap {
        top: 0;
      }
      #main-nav {
        top: 90px;
      }
    }
  }
  &.hide-top-info {
    #main-nav {
      //top: 90px;
    }
  }
  .info-noprofit {
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 2;
  }
}
.site-logo {
  position: relative;
  .site-title {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 175px;
    font-family: $openSansCondensed;
    font-size: 22px;
    font-weight: 500;
    padding-top: 10px;
    a {
      color: $sectionTitleColor;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
.site-footer {
  background-color: $footerBg;
  color: #fff;
  padding: 43px 0;
  font-size: 13px;
  a {
    color: #fff;
  }
  .fa-heart {
    color: #ff2525;
  }
  .footer-right {
    text-align: right;
    a {

    }
  }
}

/*--------------------------------------------------------------
## Common styles
--------------------------------------------------------------*/
.white-blue-bg {
  background: url('../images/white-blue-bg.svg') repeat-y;
  background-size: 101%;
}
.site-logo-wrap {
  padding: 30px 15px 130px;
}
.col-content {
  background: #fff;
  padding: 0;
}
.mobile-head {
  padding: 0;
  .single-sidebar-wrap {
    background: $lightBlue;
    padding-bottom: 55px;
    position: relative;
  }
}
#sintetic-bg-lblue {
  background: $lightBlue;
  position: absolute;
  top: 0;
  left: 0;

  //border: 1px solid red;
  //width: 50px;
  //height: 50px;
}

.sidebar-blog-wrap,
.sidebar-event-wrap {
  padding: 60px 15px 0;
  .blog-sidebar-title,
  .event-sidebar-title {
    color: #cacaca;
    font-family: $palatino;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  ul {
    list-style-type: none;
    li {
      margin-bottom: 35px;
      &:after {
        margin-top: 35px;
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background-color: #525252;
      }
      a {
        display: block;
        color: #58c8fd;
        font-family: $mainFont;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 14px;
      }
      .widget-post-date {
        color: #525252;
        font-family: $palatino;
        font-size: 15px;
        font-weight: 700;
        font-style: italic;
      }
    }
  }
}

.sidebar-event-wrap {
  color: darkgrey;
  font-size: 15px;
  font-weight: 300;
  margin-top: 50px;
  padding: 0 15px;
  .event-sidebar-title {
    margin-top: 60px;
    color: darkgrey;
    font-family: $palatino;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
  }
}

.organizers-wrap {
  padding-top: 30px;
  .c-table {
    border-spacing: 0 15px;
    width: 100%;
  }
  .c-cell {
    vertical-align: middle;
  }
  .second-cell {
    border-left: 3px solid rgba(82, 82, 82, 0.09);
    padding-left: 28px;
    .fa {
      color: #d7d7d7;
      font-size: 14px;
      padding-right: 5px;
    }
  }
  .organizers-title {
    color: #222;
    font-family: $palatino;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    padding-bottom: 30px;
  }
  .img-organizer-wrap {
    float: left;
    margin-right: 15px;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
  .organizer-info {
    //float: left;
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 30px;
    .organizer-name {
      color: black;
      font-size: 20px;
      font-weight: 400;
    }
    .organizer-content {
      color: #c2c2c2;
      font-family: $palatino;
      font-size: 15px;
      font-style: italic;
    }
  }
  .organizer-phone {
    color: #525252;
    font-size: 15px;
    font-weight: 700;
  }
}

.slogan-wrap {
  padding: 30px 15px;
  .slogan-site-name {
    color: rgba(0, 0, 0, 0.31);
    font-family: $palatino;
    font-size: 16px;
    font-weight: 700;
    font-style: italic;
  }
  .slogan-site-text {
    color: black;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.main-post-nav {
  margin: 10px 60px;
  padding: 20px 0;
  border-top: 1px solid #f0f0f0;
  .page-numbers {
    line-height: 46px;
    border: 2px solid #fff;
    height: 50px;
    width: 50px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    color: #000;
    &:hover {
      border: 2px solid #f0f0f0;
      border-radius: 50%;
    }
  }
  .prev,
  .next {
    border: 2px solid #f0f0f0;
    border-radius: 50%;
    font-size: 20px;
    &:hover {
      background: $activeColor;
      border-color: $activeColor;
      color: #fff;
    }
  }
  .page-numbers.current {
    border: 2px solid $activeColor;
    border-radius: 50%;
  }
}

.img-title-wrap {
  margin: 40px 0;
  .img-title-left {
    position: relative;
    &:hover {
      .fa {
        color: $activeColor;
      }
    }
    .fa {
      position: absolute;
      left: 0;
      bottom: 0;
      color: #fff;
      font-size: 24px;
      margin: 0 0 20px 20px;
      transition: color .6s;
    }
    .img-title-left-link {
      img {
        width: 100%;
      }
    }
  }
  .img-title-right {
    position: relative;
    .title-text {
      color: #2e2e2e;
      font-size: 15px;
      &:before {
        margin-bottom: 20px;
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background-color: #525252;
      }
    }
    .img-title-right-link {
      color: #00b9fc;
      font-family: $palatino;
      font-size: 14px;
      font-weight: 700;
      font-style: italic;
      margin-top: 30px;
      display: block;
    }
  }
}

.random-photos-wrap {
  margin-right: 30px;
  max-width: 240px;
  margin-top: 60px;
  margin-bottom: 60px;
  .photos-title {
    margin-bottom: 30px;
    color: #cacaca;
    font-family: $palatino;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
  }
  .photos-block {
    margin-bottom: 30px;
    .random-photo-link {
      display: block;
      margin-bottom: 15px;
      img {
        //width: 100%;
      }
    }
  }
}
// Общие стили для блога, события, галереи - single pages
.common-single {
  position: relative;
  .single-thumbnail {
    img {
      width: 100%;
      height: auto;
    }
  }
  .post-date {
    padding-left: 15px;
    .day {
      float: left;
      color: #222;
      font-family: $openSansCondensed;
      font-size: 105px;
      font-weight: 300;
      margin-right: 10px;
    }
    .additional-date {
      float: left;
      padding-top: 35px;
    }
    .calendar-icon {
      color: #d8e2e5;
      font-size: 22px;
      margin-bottom: 10px;
    }
    .weekday,
    .month-year {
      font-family: $palatino;
      color: #222;
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
      line-height: 21px;
    }
  }
  .page-description {
    color: #a3bdc9;
    font-size: 15px;
    font-weight: 400;
    padding: 15px 30px 0 15px;
  }
  .page-title {
    font-family: $playfairDisplay;
    color: #a3beca;
    font-size: 33px;
    font-weight: 700;
    font-style: italic;
    padding-left: 15px;
    padding-right: 30px;
  }
  .single-content-wrap {
    padding: 30px 60px;
    .single-title {
      color: #222;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 10px;
    }
  }
  .default-link {
    margin-bottom: 30px;
    a {
      font-family: $palatino;
      font-size: 15px;
      font-weight: 700;
      font-style: italic;
    }
    &:after {
      margin-top: 22px;
      content: '';
      display: block;
      width: 70px;
      height: 3px;
      background-color: #525252;
    }
  }

  .month-year-wrap {
    //position: relative;
    color: rgba(34, 34, 34, 0.35);
    .line-before {
      display: inline-block;
      width: 70px;
      height: 3px;
      background: rgba(34, 34, 34, 0.35);
      margin-bottom: 3px;
      margin-right: 10px;
    }
    .month-year {
      display: inline-block;
      font-family: $palatino;
      font-weight: 700;
      font-style: italic;
    }
  }
  .order-link-side {
    position: absolute;
    //bottom: 0;
    top: 100%;
    margin-top: -31px;
    margin-left: 15px;
    border: 6px solid #fff;
    height: 62px;
    line-height: 51px;
    border-radius: 34px;
  }
  .event-program {
    margin-top: 90px;
    padding: 0 15px;
    .program-title {
      color: #222;
      font-family: $palatino;
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
      text-transform: uppercase;
      padding-bottom: 30px;
    }
    .first-cell {
      min-width: 80px;
      color: #3a3a3a;
      font-family: $palatino;
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
    }
    .second-cell {
      color: #717171;
      font-family: $palatino;
      font-size: 15px;
      font-weight: 700;
      font-style: italic;
    }
    .c-cell {
      padding-bottom: 10px;
    }
  }
}

/*--------------------------------------------------------------
## Event page
--------------------------------------------------------------*/
.event-single {
  .single-entry {
    margin-top: 15px;
  }
  .order-ticket-form-wrap {
    background-color: $lightBlue;
    padding: 30px 160px 46px 60px;
    input {
      border: none;
      width: 100%;
    }
    input[type="submit"] {
      @extend .btn-blue;
      width: 100%;
    }
    .order-title {
      color: #a3bec9;
      font-family: $playfairDisplay;
      font-size: 33px;
      font-weight: 700;
      font-style: italic;
      margin-bottom: 28px;
    }
    .c-table {
      border-spacing: 15px;
      //table-layout: fixed;
    }
    .c-cell {
      //width: 50px;
    }
    .captcha-img {
      width: 48%;
      border: 1px solid #cfdee3;
      display: inline-block;
      height: 50px;
      border-radius: 25px;
      text-align: center;
      line-height: 50px;
    }
    .captcha-txt {
      display: inline-block;
      width: 48%;
    }
  }
  .default-link {
    float: left;
  }
  .order-link-content {
    float: right;
  }
  .event-location {
    margin: 15px 0 20px 0;
    .fa {
      color: #e0e0e0;
      font-size: 18px;
    }
    color: #222;
    font-family: $palatino;
    font-size: 15px;
    font-weight: 700;
    font-style: italic;
    .loc-link {
      margin-left: 30px;
    }
  }
  .events-gallery-container {
    padding: 20px 60px;
  }

  .wpcf7-not-valid-tip {
    display: none;
  }
  .wpcf7-validation-errors {
    display: none !important;
  }

  .wpcf7-response-output {
    border: none;
    margin: 0;
    padding: 0;
    color: #a3bec9;
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    padding-top: 10px;
  }
  .wpcf7-form.sent {
    padding-top: 20px;
    .c-table {
      display: none;
    }
    .ajax-loader {
      display: none;
    }
  }
  .subscribe-email {
    //background: rgba(28, 214, 212, 0.37);
    background: rgba(255, 255, 255, 0.38);
    border: none;
    color: #fff;
    margin-right: 20px;
    max-width: 250px;
  }
  input.wpcf7-not-valid {
    border: 2px solid #cb4a3c;
  }
}

/*--------------------------------------------------------------
## Blog list page
--------------------------------------------------------------*/
.blog-list,
.events-list {
  .blog-list-wrap,
  .event-list-wrap {
    padding: 30px 60px;
  }
  .list-title {
    a {
      color: #070a09;
      font-size: 20px;
      font-weight: 700;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .thumb-img {
    background: no-repeat center center;
    background-size: cover;
    height: 160px;
  }
  .blog-date,
  .event-date {
    a {
      color: #222;
      font-family: $palatino;
      font-size: 15px;
      font-weight: 700;
      font-style: italic;
      &:hover {
        text-decoration: underline;
      }
      &:after {
        margin-top: 20px;
        margin-bottom: 20px;
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background-color: #525252;
      }
    }
    .fa {
      color: #00b9fc;
    }
  }
  .blog-excerpt,
  .event-excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;

    margin-bottom: 10px;
  }
  .blog-item,
  .event-item {
    margin-bottom: 30px;
  }
}


/*--------------------------------------------------------------
## Gallery post
--------------------------------------------------------------*/
//.gallery-single {
  .gallery-title-wrap {
    .single-title {
      float: left;
      a {
        color: #222;
      }
    }
    .date {
      float: right;
      margin-top: 20px;

      color: #525252;
      font-family: $palatino;
      font-size: 15px;
      font-weight: 700;
      font-style: italic;
      .fa {
        margin-right: 6px;
        color: #cadde6;
        font-size: 18px;
      }
    }
  }
  .gallery-list-wrap {
    .single-content-wrap {
      &:after {
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background-color: #525252;
      }
    }
  }
  .gallery-sidebar-title {
    color: #cacaca;
    font-family: $palatino;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
  }
//}
.gallery-items {
  margin-top: 20px;
  .g-image-wrap {
    width: 100%;
    height: 218px;
    margin-bottom: 30px;
    background: no-repeat center center;
    background-size: cover;
  }
}

.gallery-slider-wrap {
  .gallery-slider-title {
    float: left;
    display: block;

    color: #cacaca;
    font-family: $palatino;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
  }
  .gallery-link {
    display: block;
    float: right;
    line-height: 70px;

    color: #00b9fc;
    font-family: $palatino;
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
  }
}
.gallery-slider {
  position: relative;
  margin-bottom: 40px;
  //.gallery-slider-title-wrap {

  //}
  .gallery-slider-item {
    height: 160px;
    display: block;
    background: no-repeat center center;
    background-size: cover;
  }
  .owl-dots {
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    .owl-dot {
      background-color: #ebf5f8;
      margin-left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      display: inline-block;
      &.active {
        background-color: #4e9efe;
      }
    }
  }
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.55;
    background-color: white;
    line-height: 160px;
    width: 25px;
    text-align: center;
    &:hover {
      opacity: 0.8;
      transition: opacity .6s;
    }
    .fa {
      color: black;
      font-size: 32px;
      font-weight: 400;
      line-height: 160px;
    }
  }
  .owl-prev {
    left: 0;
  }
  .owl-next {
    right: 0;
  }
}
/*--------------------------------------------------------------
## Donate page
--------------------------------------------------------------*/
.donation-page,
.default-page {
  background: url('../images/lblue-white-bg.svg') repeat-y;
  background-size: 100%;
  .site-logo-wrap {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .donate-sidebar-wrap,
  .default-sidebar-wrap {
    background: $lightBlue;
    min-height: 450px;
  }
  .donate-content-wrap,
  .default-content-wrap {
    background: #fff;
    min-height: 450px;
    padding-bottom: 30px;
  }
  .page-title {
    color: #9faab0;
    font-family: $playfairDisplay;
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
    text-align: right;
    transform: rotate(-90deg);
    transform-origin: 100% 100%;
  }
  .page-title-wrap {
    padding-right: 20px;
  }



}
.donation-page {
  .donate-form-wrap {
    padding: 62px 96px 0;
    margin-bottom: 50px;
  }
  .form-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 33px;
  }
  .donate-form {
    background-color: #f7f7f7;
    padding: 25px 30px;
    position: relative;
    .donate-fields {
      .tRow {
        margin-bottom: 10px;
      }
      .tCell {
        display: inline-block;
      }
      .form-label {
        width: 150px;
      }
    }
    .paypal-img {
      float: right;
    }
    .paypal-img-mobile {
      display: none;
    }
    .donate-action {
      position: absolute;
      left: 50%;
      margin-left: -68px;
      .donate-submit {
        border: 6px solid #fff;
        height: 62px;
        line-height: 53px;
        border-radius: 34px;
        //padding-top: 10px;
      }
    }
  }
  .donate-other {
    font-weight: 700;
    margin-top: 30px;
    //padding-top: 20px;
    padding: 25px 96px 25px 96px;
    background-color: #f7f7f7;
    .fa {
      color: #d0d9de;
    }
    .other-title {
      color: #9faab0;
      font-weight: 400;
      font-style: italic;
      margin-bottom: 17px;
    }
    .tel a {
      color: $sectionTitleColor;
    }
    .email a {

    }
  }
  .donate-entry {
    padding: 10px 96px;
  }
}

.default-page {
  .default-entry {
    padding: 30px 60px;
  }
}
/*--------------------------------------------------------------
## About page
--------------------------------------------------------------*/
.about-page {
  .site-logo-wrap {
    padding-bottom: 30px;
  }
  .page-description {
    &:after {
      margin-top: 30px;
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background: #a3bdc9;
    }
  }
  .contacts-wrap {
    padding-top: 18px;
  }
  .contact-label {
    margin-top: 20px;
    color: #a9b2b6;
    font-family: $palatino;
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
  }
  .address-content {
    color: #070a09;
    font-size: 14px;
    font-weight: 700;
    .address-link {
      font-family: $palatino;
      font-size: 14px;
      font-weight: 700;
      font-style: italic;
      display: block;
    }
  }
  .tel-content {
    a {
      color: #070a09;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .email {
    a {
      color: $activeColor;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .entry {
    padding: 30px 60px;
    .about-title {
      color: #222;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 30px;
      &:after {
        margin-top: 24px;
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background: #222;
      }
    }
  }

  .col-img {
    padding: 0;
    img {
      width: 100%;
    }
  }
  .full-img-text {
    &:before {
      margin-bottom: 28px;
      content: '';
      display: block;
      width: 70px;
      height: 3px;
      background: #222;
    }
    margin-top: 60px;
    padding-right: 40px;
  }
  .img-section {
    background: #fff;
  }
  .links-wrap {
    background: #fff;
  }
  .links-bg-wrapper {
    padding-top: 60px;
    background: #fff;
  }
  .links-title {
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
    color: rgba(160,160,160,.26);
    font-family: $palatino;
    text-align: right;
  }
  .link-item-entry {
    padding-bottom: 50px;
    padding-right: 30px;
    .link-title {
      color: #525252;
      font-size: 15px;
      font-weight: 700;
    }
    .link {
      color: $activeColor;
      font-family: $palatino;
      font-size: 15px;
      font-weight: 700;
      font-style: italic;
      padding: 20px 0;
      display: block;
      &:after {
        margin-top: 24px;
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background: #222;
      }
    }
    .content {
      color: #515151;
      font-size: 15px;
      font-weight: 300;
    }
  }

  .about-footer {
    background: #fff;
  }
  .site-logo-wrap {
    //height: 220px;
  }
  .waiting-for-you {
    background: #fff;
    min-height: 220px;
    padding-bottom: 55px;
  }
  .waiting-for-you-wrap {
    border-top: 3px solid #f1f1f1;
    border-bottom: 3px solid #f1f1f1;
    padding: 37px 30px 45px 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    .youth-assoc {
      color: #bdbdbd;
      font-family: $playfairDisplay;
      font-size: 26px;
      font-weight: 900;
      font-style: italic;
    }
    .waiting-text {
      color: #5f5f5f;
      font-size: 38px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .social-block {
      padding-top: 20px;
      .social-links li a {
        background: $activeColor;
        color: #fff;
        margin-right: 15px;
      }
    }
  }
}

.mission-page {
  .entry {
    padding: 30px 60px;
    .about-title {
      margin-bottom: 30px;
    }
    .title {
      color: #222;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      &:after {
        margin-top: 24px;
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background: #222;
      }
    }
    .short-entry {
      color: #949494;
      font-size: 14px;
      font-weight: 300;
    }
  }


  .col-img {
    padding: 0;
    background: #fff;
  }
  .full-img-text {
    &:before {
      margin-bottom: 28px;
      content: '';
      display: block;
      width: 70px;
      height: 3px;
      background: #222;
    }
    //margin-top: 60px;
    padding-right: 40px;
  }
  .img-section {
    background: #fff;
    position: relative;
    img {
      position: relative;
    }
  }
  .blue-square {
    width: 217px;
    height: 217px;
    background-color: #ebf5f8;
    position: absolute;
    top: -60px;
    right: 80px;
  }
  .blue-square-2 {
    bottom: 40px;
    right: 38%;
    top: auto;
  }
  .links-wrap {
    background: #fff;
  }
  .links-bg-wrapper {
    padding-top: 60px;
    background: #fff;
  }
  .links-title {
    font-weight: 700;
    font-size: 70px;
    line-height: 1;
    color: rgba(160,160,160,.26);
    font-family: $palatino;
    text-align: right;
  }
  .link-item-entry {
    padding-bottom: 50px;
    padding-right: 30px;
    .link-title {
      color: $activeColor;
      font-family: $palatino;
      font-size: 15px;
      font-style: italic;
    }
    .content {
      color: #515151;
      font-size: 15px;
      font-weight: 300;
    }
  }

  .about-footer {
    background: #fff;
  }

  .waiting-for-you {
    background: #fff;
    min-height: 220px;
    padding-bottom: 55px;
  }
  .waiting-for-you-wrap {
    border-top: 3px solid #f1f1f1;
    border-bottom: 3px solid #f1f1f1;
    padding: 37px 30px 45px 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    .youth-assoc {
      color: #bdbdbd;
      font-family: $playfairDisplay;
      font-size: 26px;
      font-weight: 900;
      font-style: italic;
    }
    .waiting-text {
      color: #5f5f5f;
      font-size: 38px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .social-block {
      padding-top: 20px;
      .social-links li a {
        background: $activeColor;
        color: #fff;
        margin-right: 15px;
      }
    }
  }
  .site-logo-wrap {
    padding-bottom: 30px;
  }
  .page-title {
    min-height: 280px;
    h1 {
      transform: rotate(-90deg);
      transform-origin: 100% 100%;
    }
  }
}
/*--------------------------------------------------------------
## Home page
--------------------------------------------------------------*/
.home-single {
  .col-content,
  .mobile-head,
  .single-sidebar-wrap {
    height: 480px;
  }
  .slider-wrap {
    height: 487px;
    overflow-y: hidden;
    .top-slider {
      height: 480px;
      overflow-y: hidden;
      position: relative;
    }
    .owl-item {
      height: 480px;
    }
    .slide-top-item {
      height: 480px;
      overflow-y: hidden;
      background: no-repeat center center;
      background-size: cover;
    }
    .owl-controls {
      bottom: 0;
      position: absolute;
    }
    .owl-dots {
      //position: absolute;
      text-align: center;
      width: 100%;
      padding: 27px 12px;
      .owl-dot {
        background-color: #ebf5f8;
        margin-left: 6px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        display: inline-block;
        &.active {
          background-color: #4e9efe;
        }
      }
    }
  }

  .col-content {
    position: relative;
  }

  .mission-block-wrap {
    position: absolute;
    bottom: 80px;
    z-index: 2;
    background-color: rgba(0, 185, 252, 0.92);
    width: 350px;
    padding: 25px;
    left: -95px;
    padding-bottom: 50px;
    .mission-text {
      color: white;
      font-family: $palatino;
      font-size: 18px;
      font-style: italic;
      //padding-bottom: 15px;
      //border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      &:after {
        margin-top: 20px;
        width:130px;
        height: 2px;
        content: '';
        display: block;
        background: rgba(255, 255, 255, 0.2);
      }
    }
    .mission-link {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 25px;
      margin-bottom: 25px;
      padding-left: 30px;
      //background-color: rgba(0, 185, 252, 0.08);
      a {
        //background: #fff;
      }
    }
  }

  .section-title-wrap {
    .section-title {
      font-style: italic;
      font-size: 24px;
      font-weight: 700;
      transform: rotate(-90deg);
      transform-origin: 100% 100%;
      text-align: right;
      font-family: $playfairDisplay;
    }
  }
  .home-events {
    background: url('../images/gray-blue-bg.svg') repeat-y;
    background-size: 101%;
    .events-content-wrap {
      background: #fff;
      padding: 0;
    }
    .section-title {
      color: rgba(255, 255, 255, 0.68);
    }

    .event-item {
      width: 50%;
      height: 380px;
      float: left;
      &.has-thumb {
        .event-img {
          width: 50%;
          float: left;
        }
        .event-item-entry {
          width: 50%;
          float: left;
        }
        &.event-item-3 {
          .event-img {
            float: right;
          }
          .event-item-entry {
            float: left;
          }
        }
      }
      &.no-thumb {

      }
      .event-img {
        background: no-repeat center center;
        background-size: cover;
        height: 380px;
        img {
          display: none;
        }
      }

      .event-item-entry {
        padding: 20px;
        position: relative;
        height: 380px;
        overflow-y: hidden;
        .event-date {
          color: $grayBlue;
          font-family: $palatino;
          font-size: 15px;
          font-weight: 700;
        }
        .event-title {
          color: #272727;
          font-size: 20px;
          font-weight: 700;
        }
        .event-location {
          color: $activeColor;
          font-family: $palatino;
          font-size: 14px;
          font-weight: 700;
          font-style: italic;
          padding-top: 20px;
        }
        .event-excerpt {
          &:before {
            margin-bottom: 20px;
            content: '';
            display: block;
            width: 70px;
            height: 3px;
            background-color: #525252;
          }
          margin-top: 20px;
          color: #cacaca;
          font-size: 15px;
          font-weight: 400;
          max-height: 84px;

          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .event-link {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 30px 20px 20px;
          background: #fff;
        }
      }
    }

    .event-item-info-block {
      background-color: #ebf5f8;
      text-align: center;
      padding: 100px 50px 0 50px;
      .site-name {
        color: rgb(175, 175, 175);
        font-family: $palatino;
        font-size: 25px;
        font-weight: 700;
        font-style: italic;
      }
      .event-link-block-title {
        margin-top: 22px;
        color: black;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        text-transform: uppercase;
      }
      .view-more-events {
        margin-top: 35px;
      }
    }
  }

  .make-donation-section {
    &.make-donation-section-first {
      background-color: #11d4eb;
    }
    &.make-donation-section-second {
      background-color: $activeColor;
    }
    padding: 40px 0;
    .site-name {
      color: rgba(239, 240, 240, 0.42);
      font-family: $playfairDisplay;
      font-size: 25px;
      font-weight: 900;
      font-style: italic;
    }
    .donation-title {
      color: white;
      font-size: 48px;
      font-weight: 700;
      line-height: 60px;
      text-transform: uppercase;
    }
    .donate-link {
      margin-top: 16px;
      a {
        color: #11d4eb;
      }
    }
    .donate-text {
      margin-top: 7px;
      color: #f0f0f0;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .gallery-section {
    .gallery-left {
      width: 50%;
      float: left;
      height: 662px;
      background: #fff;
      .gallery-left-small {
        width: 50%;
        float: left;
        height: 50%;
        overflow: hidden;
      }
    }
    .gallery-right {
      height: 662px;
      width: 50%;
      float: left;
      overflow: hidden;
      background: #fff;
      .gallery-right-big {
        height: 100%;
      }
    }
    .gallery-left-small-first-bg {
      background: no-repeat center center;
      background-size: cover;
      height: 100%;
      text-align: center;
      padding: 100px 15px 0 15px;
      .text-first {
        color: #727272;
        font-family: $palatino;
        font-size: 25px;
        font-weight: 700;
        font-style: italic;
      }
      .text-second {
        margin-top: 10px;
        color: black;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        text-transform: uppercase;
      }
    }

    .gallery-left-small-nav {
      position: relative;
      //border: 1px solid red;
      height: 100%;
      .gallery-nav {
        .left-slide,
        .right-slide {
          width: 95px;
          height: 100px;
          background-color: #c6c6c6;
          color: #fff;
          text-align: center;
          display: inline-block;
          transition: all .6s;
          cursor: pointer;
          .fa {
            font-size: 100px;
          }
        }
        .left-slide {
          &:hover {
            background-color: #959595;
            padding-right: 10px;
          }
        }
        .right-slide {
          &:hover {
            background-color: #959595;
            padding-left: 10px;
          }
        }
      }

      .gallery-title {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #bebebe;
        font-family: $playfairDisplay;
        font-size: 84px;
        font-style: italic;
        font-weight: 700;
        margin-bottom: -94px;
        transform: rotate(90deg);
        transform-origin: 100% 0;
      }
      .gallery-link {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 45px 34px;
      }
    }

    .gallery-item {
      height: 100%;
      position: relative;
    }

    .gallery-item-1 {
      .gallery-img {
        transition: opacity .8s;
      }
    }
    .gallery-item-2 {
      .gallery-img {
        transition: opacity .5s;
      }
    }
    .gallery-item-3 {
      .gallery-img {
        transition: opacity .3s;
      }
    }

    .gallery-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      //display: none;
      background: no-repeat center center;
      background-size: cover;
      //transition: opacity .8s;
      &.gallery-img-0 {
        //display: block;
        opacity: 1;
      }

      &.gallery-img-show {
        opacity: 1 !important;
      }
      &.gallery-img-hidden {
        opacity: 0 !important;
      }
    }
  }

  .subscribe-section {
    background-color: #1cd6d4;
    padding-top: 25px;
    padding-bottom: 35px;
    .subscribe-text-wrap {
      border-right: 1px solid rgba(255, 255, 255, 0.35);
      padding-right: 70px;
    }
    .subscribe-site {
      color: rgba(239, 240, 240, 0.41);
      font-family: $playfairDisplay;
      font-size: 28px;
      font-weight: 900;
      font-style: italic;
      line-height: 35px;
    }
    .subscribe-text {
      color: white;
      font-size: 44px;
      font-weight: 700;
      line-height: 46px;
      text-transform: uppercase;
    }
    .subscribe-text-1 {
      color: white;
      font-size: 27px;
      font-weight: 700;
      line-height: 36px;
      text-transform: uppercase;
    }
    .subscribe-form-wrap {
      //padding-top: 30px;
      padding-left: 60px;
    }
    .wpcf7-form {
      padding-top: 30px;
    }
    .wpcf7-form.sent {
      padding-top: 20px;
      .ajax-loader {
        display: none;
      }
      .subscribe-email,
      .subscribe-submit {
        display: none;
      }
    }
    .subscribe-email {
      //background: rgba(28, 214, 212, 0.37);
      background: rgba(255, 255, 255, 0.38);
      border: none;
      color: #fff;
      margin-right: 20px;
      max-width: 250px;
      &.wpcf7-not-valid {
        border: 2px solid #cb4a3c;
      }
    }
    .wpcf7-not-valid-tip {
      display: none;
    }
    .wpcf7-validation-errors {
      display: none !important;
    }
    .subscribe-submit {
      padding: 0 60px;
      outline: none;
      color: #1cd6d4;
    }

    .wpcf7-response-output {
      border: none;
      margin: 0;
      padding: 0;
      color: white;
      font-size: 32px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      padding-top: 10px;
    }

    ::-webkit-input-placeholder {color:#fff;}
    ::-moz-placeholder          {color:#fff;}/* Firefox 19+ */
    :-moz-placeholder           {color:#fff;}/* Firefox 18- */
    :-ms-input-placeholder      {color:#fff;}
  }

  .blog-section {
    background: #ebf5f8;
    .blog-section-title {
      color: #a3bec9;
      font-family: $playfairDisplay;
      //padding-top: 40px;
      margin-left: 30px;
      font-weight: 700;
      line-height: 15px;
      font-style: italic;
      font-size: 64px;
      transform: rotate(90deg);
      transform-origin: 0 100%;
    }
    .blog-item {
      height: 320px;
      overflow: hidden;
      .blog-img {
        height: 320px;
        background: no-repeat center center;
        background-size: cover;
        img {
          display: none;
        }
      }
      &.has-thumb {
        .blog-img,
        .blog-entry {
          width: 50%;
          float: left;
        }
      }

      &.blog-item-2 {
        .blog-img {
          float: right;
        }
      }

      &.blog-item-3 {
        .blog-entry {
          background: #ebf5f8;
        }
        .blog-more {
          background: #ebf5f8;
          .read-more {
            border-color: #cad2d5;
          }
        }
        .blog-more-wrap {
          width: 50%;
          float: left;
          background: #fff;
          height: 320px;
          padding: 55px 30px;
          text-align: center;
          .site-name {
            color: #afafaf;
            font-family: $palatino;
            font-size: 25px;
            font-weight: 700;
            font-style: italic;
          }
          .link-title {
            margin-top: 18px;
            margin-bottom: 35px;
            color: black;
            font-size: 40px;
            font-weight: 700;
            line-height: 44px;
            text-transform: uppercase;
          }
          .blog-more-btn {

          }
        }
      }

      .blog-entry {
        background: #fff;
        padding: 28px 64px;
        height: 320px;
        overflow: hidden;
        position: relative;
      }
      .blog-date {
        color: $activeColor;
        font-style: italic;
        font-size: 15px;
        font-weight: 700;
        text-align: right;
      }
      .blog-title {
        a {
          color: #070a09;
          font-size: 20px;
          font-weight: 700;
        }
      }
      .blog-content {
        padding-top: 15px;
        color: #888;
        font-size: 15px;
        font-weight: 400;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 100px;

      }
      .blog-more {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        padding-left: 64px;
        padding-bottom: 28px;
        padding-top: 10px;
      }
    }
  }

  .about-us-section {
    background: url('../images/gray-white-bg.svg') repeat-y;
    background-size: 101%;
    .about-side-top,
    .about-side-btm {
      padding-right: 0;
    }

    .about-side-top {
      .about-title {
        color: white;
        font-family: $playfairDisplay;
        font-size: 36px;
        font-weight: 700;
        font-style: italic;
        text-align: right;
        margin-right: 30px;
        margin-top: -20px;
        transform: rotate(-90deg);
        transform-origin: 100% 100%;
      }
    }
    .about-side-btm {
      height: 230px;
      position: relative;
      img {
        height: 100%;
        max-width: none;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .about-content-top {
      background: #fff;
      .site-logo {
        margin: 72px 100px 42px 32px;
        //.site-title {
        //  margin-left: 130px;
        //  padding-top: 5px;
        //}
      }

      .join-text {
        margin-top: 71px;
        margin-bottom: 23px;
        color: #9aa7ac;
        font-size: 15px;
        font-weight: 400;
      }
      .join-link-wrap {
        margin-bottom: 30px;
      }
    }
    .about-content-btm {
      background-color: #e0f1f7;
      height: 230px;
      .about-text {
        margin: 38px 50px 25px 94px;
        color: #9aa7ac;
        font-size: 15px;
        line-height: 18px;
      }
      .about-link {
        margin-left: 94px;
      }
      .contacts-wrap {
        padding-top: 18px;
      }
      .contact-label {
        margin-top: 20px;
        color: #a9b2b6;
        font-family: $palatino;
        font-size: 14px;
        font-weight: 700;
        font-style: italic;
      }
      .address-content {
        color: #070a09;
        font-size: 14px;
        font-weight: 700;
        .address-link {
          font-family: $palatino;
          font-size: 14px;
          font-weight: 700;
          font-style: italic;
          margin-left: 20px;
        }
      }
      .tel-content {
        a {
          color: #070a09;
          font-size: 14px;
          font-weight: 700;
        }
      }
      .email {
        a {
          color: $activeColor;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  .video-section {
    background: #fff;
    padding-top: 48px;
    padding-bottom: 43px;
    .video-entry {
      padding-right: 40px;
      .title {
        color: #616161;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 28px;
        &:after {
          display: block;
          content: '';
          width: 70px;
          height: 3px;
          background-color: #525252;
          margin-top: 26px;
        }
      }
      .video-text {
        color: #757575;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 43px;
      }
      .link-youtube {

      }
      .video-wrap {

      }
    }
  }

  .footer-section {
    background: url('../images/lblue-gray-bg.svg') repeat-y;
    background-size: 101%;
    .wpcf7-not-valid-tip {
      display: none;
    }
    .wpcf7-not-valid {
      border-color: red;
    }

    .wpcf7-validation-errors {
      display: none !important;
    }
    .wpcf7-form.sent {
      .send-msg-wrap {
        display: none;
      }
      .wpcf7-response-output {
        border: none;
        color: #57666d;
        font-size: 32px;
        font-weight: 700;
        //line-height: 60px;
        text-transform: uppercase;
        margin: 0;
        text-align: center;
        padding: 0 100px 0 0;
      }
    }
    .form-title {
      color: #a3bec9;
      font-family: $playfairDisplay;
      font-size: 33px;
      font-weight: 700;
      font-style: italic;
      margin-bottom: 27px;
    }
    .name-row {
      input {
        width: 100%;
      }
    }
    .subj-row {
      margin-top: 12px;
      input {
        width: 100%;
      }
    }
    .msg-row {
      margin-top: 12px;
      .submit-main-form {
        vertical-align: top;
        width: 37%;
        border: none;
      }
      textarea {
        &.wpcf7-not-valid {
          border-color: red;
        }
        max-width: 60%;
        border-radius: 0;
        border: none;
        background: $lightBlue;
        border-bottom: 2px solid #fff;
        resize: none;
        max-height: 80px;
      }
    }
    .social-footer-block {
      background: #fff;
      height: 312px;
      text-align: center;
      padding-top: 80px;
      .site-name {
        color: #afafaf;
        font-family: $palatino;
        font-size: 25px;
        font-weight: 700;
        font-style: italic;
      }
      .come-join {
        margin-top: 21px;
        color: black;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        text-transform: uppercase;
      }
      .social-footer-icons {
        margin-top: 31px;
        .social-links li {
          background: $activeColor;
          margin-right: 12px;
          a {
            color: #fff;
          }
        }
      }
    }
  }
}

.amazon-section {
  padding: 40px 0;
  background: #fe9b00;
  .amazon-logo {
    img {
      max-width: 300px;
    }
  }
  .amazon-link {
    margin-top: 12px;
    a {
      color: #fe9b00;
    }
  }
  .amazon-text  {
    color: #fff;
    margin-top: 16px;
  }
}


/*--------------------------------------------------------------
## Corrections for Russian lang
--------------------------------------------------------------*/
.page-ru-lang {
  .events-content-title {
    .section-title-wrap {
      margin-top: 60px;
    }
  }
  .home-events .event-item-info-block .site-name {
    font-size: 20px;
  }
  .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    font-size: 60px;
    margin-bottom: -50px;
    margin-right: -15px;
  }
  .home-single .subscribe-section .subscribe-site {
    font-size: 22px;
  }
  .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap {
    .site-name {
      font-size: 20px;
    }
  }
  .home-single .footer-section .social-footer-block {
    padding-top: 50px;
  }
  .site-logo .site-title {
    font-size: 18px;
    padding-top: 20px;
  }
  .slogan-wrap .slogan-site-text {
    font-size: 24px;
  }

  .gallery-section .gallery-left-small-first-bg .text-second {
    font-size: 32px;
  }

  .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .link-title {
    font-size: 32px;
  }

  @include apply-to(max, (1200) ) {
    .site-logo .site-title {
      font-size: 16px;
      padding-top: 16px;
    }
    .home-single .gallery-section .gallery-left-small-nav .gallery-title {
      font-size: 48px;
      margin-right: 10px;
      margin-bottom: 35px;
    }
    .home-single .gallery-section .gallery-left-small-nav .gallery-link {
      a {
        font-size: 12px;
      }
    }

    .home-single {
      .subscribe-section {
        .subscribe-site {
          font-size: 20px;
        }
        .subscribe-text {
          font-size: 34px;
        }
        .subscribe-text-wrap {
          padding-right: 30px;
        }
        .subscribe-form-wrap {
          padding-left: 30px;
        }
        .subscribe-submit {
          padding: 0 30px;
        }
      }
    }

    .page-title {
      h1 {
        font-size: 28px;
      }
    }
  }

  @include apply-to(max, (992) ) {
    .site-logo .site-title {
      font-size: 14px;
      padding-top: 2px;
    }

    .event-link {
      .read-more {
        font-size: 10px;
      }
    }
    .home-single .gallery-section .gallery-left-small-nav .gallery-title {
      font-size: 36px;
    }
    .home-single .gallery-section .gallery-left-small-nav .gallery-link {
      a {
        font-size: 9px;
      }
    }
    .page-title {
      h1 {
        font-size: 24px;
      }
    }

    .slogan-wrap .slogan-site-text {
      font-size: 14px;
    }
  }

  @include apply-to(max, (767) ) {
    .event-link {
      .read-more {
        font-size: 14px;
      }
    }
    .home-single .subscribe-section .subscribe-form-wrap {
      padding-left: 0;
    }
    .home-single .subscribe-section .subscribe-text-wrap {
      padding-right: 0;
    }
    .top-header-wrap .mobile-site-logo {
      width: 170px;
      .site-title {
        padding-top: 0;
      }
    }
  }
  @include apply-to(max, (425) ) {
    .home-single .gallery-section .gallery-left-small-nav .gallery-title {
      font-size: 32px;
      padding-left: 5px;
    }
    .home-single .gallery-section .gallery-left-small-nav .gallery-link {
      padding: 10px;
      a {
        font-size: 9px;
        //padding: 0 10px;
      }
    }

  }
}

.directors-fields {
  padding: 30px 60px;
  .director-item {
    padding: 30px 0;
    border-bottom: 1px solid rgba(145, 145, 145, 0.15);
  }
  .director-content {
    .name {
      margin: 0;
      color: #272727;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .position {
      margin-bottom: 10px;
      color: #717171;
      font-family: $palatino;
      font-size: 15px;
      font-weight: 700;
      font-style: italic;
    }
    .boigraphy {

    }
  }
}


#up-arrow {
  position: fixed;
  font-size: 28px;
  color: #9faab0;
  bottom: 100px;
  z-index: 2;
  right: 50px;
  padding: 10px 16px;
  border-radius: 5px;
  //background: rgba(0, 0, 0, 0.4);
  background: rgba(223, 233, 236, 0.59);
  transition: all .6s;
  display: none;
  box-shadow: 0 0 10px rgba(0,0,0, 0.24);
  &:hover {
    background: #9faab0;
    color: rgba(223, 233, 236, 1);
  }
}

.fixed-header {
  #up-arrow {
    display: block;
  }
}