html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  box-sizing: border-box; }

body {
  font-family: "Open Sans", sans-serif; }

* {
  margin: 0;
  padding: 0; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a:active,
a:hover {
  outline: 0; }

b,
strong {
  font-weight: bold; }

svg:not(:root) {
  overflow: hidden; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both; }

p {
  margin-bottom: 1.5em; }

dfn,
cite,
em,
i {
  font-style: italic; }

iframe {
  max-width: 100%; }

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
a {
  color: #00b9fc;
  transition: color .6s; }
  a:active, a:hover, a:focus {
    outline: 0;
    text-decoration: none; }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
select,
textarea {
  padding: 13px 35px;
  height: 50px;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  border-radius: 25px;
  transition: padding .5s;
  color: #000;
  border: 1px solid #e5e5e5;
  outline: none;
  background: #fff; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  select:focus,
  textarea:focus {
    border: 1px solid #d4d4d4;
    padding-left: 30px;
    padding-right: 40px; }

input[type="submit"] {
  outline: none; }

.single-entry ul > li {
  margin-left: 18px; }

img {
  max-width: 100%;
  height: auto; }

img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.alignright {
  float: right; }

.alignleft {
  float: left; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.social-links {
  list-style-type: none; }
  .social-links li {
    border-radius: 50%;
    display: inline-block;
    text-align: center; }
    .social-links li a {
      border-radius: 50%;
      font-size: 24px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      display: inline-block; }

.t-hide {
  display: none; }

.clear {
  clear: both; }

.btn, input[type="submit"], .btn-blue, .event-single .order-ticket-form-wrap input[type="submit"], .btn-gray, .btn-white {
  height: 50px;
  font-size: 15px;
  border-radius: 25px;
  transition: all .5s;
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
  padding: 0 20px;
  text-transform: uppercase;
  text-decoration: none; }
  .btn:hover, input[type="submit"]:hover, .btn-blue:hover, .event-single .order-ticket-form-wrap input[type="submit"]:hover, .btn-gray:hover, .btn-white:hover {
    text-decoration: none; }

.btn-blue, .event-single .order-ticket-form-wrap input[type="submit"] {
  background-color: #00b9fc;
  color: #fff; }
  .btn-blue:hover, .event-single .order-ticket-form-wrap input[type="submit"]:hover {
    background-color: #0094c9;
    color: #fff; }

.btn-gray {
  border: 2px solid #f0f0f0;
  color: #000;
  font-weight: 700;
  line-height: 46px; }
  .btn-gray:hover {
    background-color: #00b9fc;
    color: #fff; }

.btn-white {
  background-color: #fff;
  font-weight: 700;
  line-height: 46px;
  border: none; }

.lang-switch {
  display: inline-block;
  padding-right: 32px;
  border-right: 1px solid #ebf5f8;
  padding-left: 20px; }
  .lang-switch li {
    list-style-type: none;
    display: inline-block;
    position: relative; }
    .lang-switch li a {
      width: 50px;
      height: 50px;
      padding-top: 12px;
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 15px;
      color: #000;
      border: 2px solid #e3e3e3;
      background: #fff; }
      .lang-switch li a:hover {
        text-decoration: none; }
    .lang-switch li.current-lang {
      z-index: 2; }
      .lang-switch li.current-lang a {
        background-color: #00b9fc;
        border: 2px solid #00b9fc;
        color: #fff; }
    .lang-switch li:last-child {
      margin-left: -12px; }

.menu-btn-wrap {
  display: inline-block;
  padding-left: 32px;
  font-weight: 700;
  text-transform: uppercase; }
  .menu-btn-wrap .mobile-nav-btn {
    cursor: pointer;
    font-size: 50px;
    height: 20px;
    vertical-align: middle;
    position: relative;
    z-index: 11;
    display: inline-block;
    width: 35px;
    margin-left: 16px; }
    .menu-btn-wrap .mobile-nav-btn [class^="line"] {
      display: block;
      height: 4px;
      margin-bottom: 5px;
      width: 100%;
      background-color: #00b9fc;
      transition: all .3s ease;
      border-radius: 3px; }
      .menu-btn-wrap .mobile-nav-btn [class^="line"]:first-child {
        margin-top: 0; }
      .menu-btn-wrap .mobile-nav-btn [class^="line"]:last-child {
        margin-bottom: 0; }

.c-table {
  display: table; }

.c-row {
  display: table-row; }

.c-cell {
  display: table-cell; }

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
#main-nav {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
  width: 100%;
  transition: all .5s;
  height: 0;
  overflow: hidden; }
  #main-nav ul.lang-switch {
    margin-top: 0;
    border-right: none;
    padding: 30px;
    position: absolute;
    display: none;
    top: 0;
    left: 0; }
  #main-nav .main-mob-menu {
    display: none;
    list-style-type: none;
    text-align: center;
    margin-top: 100px;
    text-transform: uppercase;
    font-size: 24px; }
    #main-nav .main-mob-menu li {
      margin-bottom: 20px; }
    #main-nav .main-mob-menu a {
      transition: all .5s; }
      #main-nav .main-mob-menu a:hover {
        text-decoration: none;
        color: #fff; }
  #main-nav .social-links-wrap {
    position: absolute;
    bottom: 0;
    display: none;
    margin-bottom: 100px;
    padding-left: 0;
    text-align: center;
    width: 100%; }
    #main-nav .social-links-wrap .social-links li a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 24px; }

.menu-opened .mobile-nav-btn .line1 {
  transform-origin: 0 0;
  transform: rotate(45deg) translate(-2px, -5px); }

.menu-opened .mobile-nav-btn .line2 {
  opacity: 0; }

.menu-opened .mobile-nav-btn .line3 {
  transform-origin: 0 0;
  transform: rotate(-45deg) translate(-2px, 1px); }

.menu-opened .mobile-menu-wrap {
  transform: translateX(0px); }

.menu-opened #main-nav {
  height: 100%; }
  .menu-opened #main-nav ul.main-mob-menu {
    display: block; }

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
.info-noprofit {
  height: 41px;
  background-color: #7ad5ff;
  text-align: center;
  color: #fff;
  padding-top: 8px;
  position: relative;
  font-size: 14px; }
  .info-noprofit .close-btn {
    position: absolute;
    right: 0;
    margin-right: 25px;
    margin-top: 4px;
    cursor: pointer; }
  .info-noprofit.t-hide {
    display: none; }

.top-header-wrap {
  background: url("../images/lblue-white-bg.svg") repeat-y;
  background-size: 100%;
  border-bottom: 1px solid #f6f6f6;
  height: 90px;
  top: -90px; }
  .top-header-wrap .header-right {
    background: #fff; }
  .top-header-wrap .social-links-wrap {
    padding: 16px 0 0 0;
    height: 89px; }
  .top-header-wrap .social-links li {
    background: #fff;
    margin-right: 12px; }
    .top-header-wrap .social-links li:last-child {
      margin-right: 0; }
  .top-header-wrap .header-elements {
    background-color: #fff;
    text-align: right;
    padding: 16px 0 0 0;
    height: 89px; }
    .top-header-wrap .header-elements .donate {
      display: inline-block;
      padding-right: 32px;
      border-right: 1px solid #ebf5f8; }
      .top-header-wrap .header-elements .donate .donate-icon {
        display: none; }
  .top-header-wrap .mobile-site-logo {
    display: none; }

.fixed-header .top-header-wrap,
.menu-opened .top-header-wrap {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 3;
  top: 0;
  transition: all 1s; }

.fixed-header .site-main,
.menu-opened .site-main {
  margin-top: 90px; }

.show-top-info #main-nav {
  top: 131px; }

.hide-top-info #main-nav {
  top: 90px; }

.fixed-header #main-nav {
  top: 90px; }

.menu-opened .top-header-wrap {
  top: 0;
  transition: none; }

.menu-opened.show-top-info .top-header-wrap {
  top: 41px; }

.menu-opened.show-top-info.fixed-header .top-header-wrap {
  top: 0; }

.menu-opened.show-top-info.fixed-header #main-nav {
  top: 90px; }

.menu-opened .info-noprofit {
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 2; }

.site-logo {
  position: relative; }
  .site-logo .site-title {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 175px;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 22px;
    font-weight: 500;
    padding-top: 10px; }
    .site-logo .site-title a {
      color: #545454; }
      .site-logo .site-title a:hover {
        text-decoration: none; }

/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
.site-footer {
  background-color: #a9bcc6;
  color: #fff;
  padding: 43px 0;
  font-size: 13px; }
  .site-footer a {
    color: #fff; }
  .site-footer .fa-heart {
    color: #ff2525; }
  .site-footer .footer-right {
    text-align: right; }

/*--------------------------------------------------------------
## Common styles
--------------------------------------------------------------*/
.white-blue-bg {
  background: url("../images/white-blue-bg.svg") repeat-y;
  background-size: 101%; }

.site-logo-wrap {
  padding: 30px 15px 130px; }

.col-content {
  background: #fff;
  padding: 0; }

.mobile-head {
  padding: 0; }
  .mobile-head .single-sidebar-wrap {
    background: #ebf5f8;
    padding-bottom: 55px;
    position: relative; }

#sintetic-bg-lblue {
  background: #ebf5f8;
  position: absolute;
  top: 0;
  left: 0; }

.sidebar-blog-wrap,
.sidebar-event-wrap {
  padding: 60px 15px 0; }
  .sidebar-blog-wrap .blog-sidebar-title,
  .sidebar-blog-wrap .event-sidebar-title,
  .sidebar-event-wrap .blog-sidebar-title,
  .sidebar-event-wrap .event-sidebar-title {
    color: #cacaca;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: 40px; }
  .sidebar-blog-wrap ul,
  .sidebar-event-wrap ul {
    list-style-type: none; }
    .sidebar-blog-wrap ul li,
    .sidebar-event-wrap ul li {
      margin-bottom: 35px; }
      .sidebar-blog-wrap ul li:after,
      .sidebar-event-wrap ul li:after {
        margin-top: 35px;
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background-color: #525252; }
      .sidebar-blog-wrap ul li a,
      .sidebar-event-wrap ul li a {
        display: block;
        color: #58c8fd;
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 14px; }
      .sidebar-blog-wrap ul li .widget-post-date,
      .sidebar-event-wrap ul li .widget-post-date {
        color: #525252;
        font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
        font-size: 15px;
        font-weight: 700;
        font-style: italic; }

.sidebar-event-wrap {
  color: darkgrey;
  font-size: 15px;
  font-weight: 300;
  margin-top: 50px;
  padding: 0 15px; }
  .sidebar-event-wrap .event-sidebar-title {
    margin-top: 60px;
    color: darkgrey;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 18px;
    font-weight: 700;
    font-style: italic; }

.organizers-wrap {
  padding-top: 30px; }
  .organizers-wrap .c-table {
    border-spacing: 0 15px;
    width: 100%; }
  .organizers-wrap .c-cell {
    vertical-align: middle; }
  .organizers-wrap .second-cell {
    border-left: 3px solid rgba(82, 82, 82, 0.09);
    padding-left: 28px; }
    .organizers-wrap .second-cell .fa {
      color: #d7d7d7;
      font-size: 14px;
      padding-right: 5px; }
  .organizers-wrap .organizers-title {
    color: #222;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    padding-bottom: 30px; }
  .organizers-wrap .img-organizer-wrap {
    float: left;
    margin-right: 15px; }
    .organizers-wrap .img-organizer-wrap img {
      width: 80px;
      height: 80px;
      border-radius: 50%; }
  .organizers-wrap .organizer-info {
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 30px; }
    .organizers-wrap .organizer-info .organizer-name {
      color: black;
      font-size: 20px;
      font-weight: 400; }
    .organizers-wrap .organizer-info .organizer-content {
      color: #c2c2c2;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 15px;
      font-style: italic; }
  .organizers-wrap .organizer-phone {
    color: #525252;
    font-size: 15px;
    font-weight: 700; }

.slogan-wrap {
  padding: 30px 15px; }
  .slogan-wrap .slogan-site-name {
    color: rgba(0, 0, 0, 0.31);
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 16px;
    font-weight: 700;
    font-style: italic; }
  .slogan-wrap .slogan-site-text {
    color: black;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase; }

.main-post-nav {
  margin: 10px 60px;
  padding: 20px 0;
  border-top: 1px solid #f0f0f0; }
  .main-post-nav .page-numbers {
    line-height: 46px;
    border: 2px solid #fff;
    height: 50px;
    width: 50px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    color: #000; }
    .main-post-nav .page-numbers:hover {
      border: 2px solid #f0f0f0;
      border-radius: 50%; }
  .main-post-nav .prev,
  .main-post-nav .next {
    border: 2px solid #f0f0f0;
    border-radius: 50%;
    font-size: 20px; }
    .main-post-nav .prev:hover,
    .main-post-nav .next:hover {
      background: #00b9fc;
      border-color: #00b9fc;
      color: #fff; }
  .main-post-nav .page-numbers.current {
    border: 2px solid #00b9fc;
    border-radius: 50%; }

.img-title-wrap {
  margin: 40px 0; }
  .img-title-wrap .img-title-left {
    position: relative; }
    .img-title-wrap .img-title-left:hover .fa {
      color: #00b9fc; }
    .img-title-wrap .img-title-left .fa {
      position: absolute;
      left: 0;
      bottom: 0;
      color: #fff;
      font-size: 24px;
      margin: 0 0 20px 20px;
      transition: color .6s; }
    .img-title-wrap .img-title-left .img-title-left-link img {
      width: 100%; }
  .img-title-wrap .img-title-right {
    position: relative; }
    .img-title-wrap .img-title-right .title-text {
      color: #2e2e2e;
      font-size: 15px; }
      .img-title-wrap .img-title-right .title-text:before {
        margin-bottom: 20px;
        content: '';
        display: block;
        width: 70px;
        height: 3px;
        background-color: #525252; }
    .img-title-wrap .img-title-right .img-title-right-link {
      color: #00b9fc;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 14px;
      font-weight: 700;
      font-style: italic;
      margin-top: 30px;
      display: block; }

.random-photos-wrap {
  margin-right: 30px;
  max-width: 240px;
  margin-top: 60px;
  margin-bottom: 60px; }
  .random-photos-wrap .photos-title {
    margin-bottom: 30px;
    color: #cacaca;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase; }
  .random-photos-wrap .photos-block {
    margin-bottom: 30px; }
    .random-photos-wrap .photos-block .random-photo-link {
      display: block;
      margin-bottom: 15px; }

.common-single {
  position: relative; }
  .common-single .single-thumbnail img {
    width: 100%;
    height: auto; }
  .common-single .post-date {
    padding-left: 15px; }
    .common-single .post-date .day {
      float: left;
      color: #222;
      font-family: "Open Sans Condensed", sans-serif;
      font-size: 105px;
      font-weight: 300;
      margin-right: 10px; }
    .common-single .post-date .additional-date {
      float: left;
      padding-top: 35px; }
    .common-single .post-date .calendar-icon {
      color: #d8e2e5;
      font-size: 22px;
      margin-bottom: 10px; }
    .common-single .post-date .weekday,
    .common-single .post-date .month-year {
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      color: #222;
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
      line-height: 21px; }
  .common-single .page-description {
    color: #a3bdc9;
    font-size: 15px;
    font-weight: 400;
    padding: 15px 30px 0 15px; }
  .common-single .page-title {
    font-family: "Playfair Display", serif;
    color: #a3beca;
    font-size: 33px;
    font-weight: 700;
    font-style: italic;
    padding-left: 15px;
    padding-right: 30px; }
  .common-single .single-content-wrap {
    padding: 30px 60px; }
    .common-single .single-content-wrap .single-title {
      color: #222;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 10px; }
  .common-single .default-link {
    margin-bottom: 30px; }
    .common-single .default-link a {
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 15px;
      font-weight: 700;
      font-style: italic; }
    .common-single .default-link:after {
      margin-top: 22px;
      content: '';
      display: block;
      width: 70px;
      height: 3px;
      background-color: #525252; }
  .common-single .month-year-wrap {
    color: rgba(34, 34, 34, 0.35); }
    .common-single .month-year-wrap .line-before {
      display: inline-block;
      width: 70px;
      height: 3px;
      background: rgba(34, 34, 34, 0.35);
      margin-bottom: 3px;
      margin-right: 10px; }
    .common-single .month-year-wrap .month-year {
      display: inline-block;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-weight: 700;
      font-style: italic; }
  .common-single .order-link-side {
    position: absolute;
    top: 100%;
    margin-top: -31px;
    margin-left: 15px;
    border: 6px solid #fff;
    height: 62px;
    line-height: 51px;
    border-radius: 34px; }
  .common-single .event-program {
    margin-top: 90px;
    padding: 0 15px; }
    .common-single .event-program .program-title {
      color: #222;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
      text-transform: uppercase;
      padding-bottom: 30px; }
    .common-single .event-program .first-cell {
      min-width: 80px;
      color: #3a3a3a;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 18px;
      font-weight: 700;
      font-style: italic; }
    .common-single .event-program .second-cell {
      color: #717171;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 15px;
      font-weight: 700;
      font-style: italic; }
    .common-single .event-program .c-cell {
      padding-bottom: 10px; }

/*--------------------------------------------------------------
## Event page
--------------------------------------------------------------*/
.event-single .single-entry {
  margin-top: 15px; }

.event-single .order-ticket-form-wrap {
  background-color: #ebf5f8;
  padding: 30px 160px 46px 60px; }
  .event-single .order-ticket-form-wrap input {
    border: none;
    width: 100%; }
  .event-single .order-ticket-form-wrap input[type="submit"] {
    width: 100%; }
  .event-single .order-ticket-form-wrap .order-title {
    color: #a3bec9;
    font-family: "Playfair Display", serif;
    font-size: 33px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 28px; }
  .event-single .order-ticket-form-wrap .c-table {
    border-spacing: 15px; }
  .event-single .order-ticket-form-wrap .captcha-img {
    width: 48%;
    border: 1px solid #cfdee3;
    display: inline-block;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    line-height: 50px; }
  .event-single .order-ticket-form-wrap .captcha-txt {
    display: inline-block;
    width: 48%; }

.event-single .default-link {
  float: left; }

.event-single .order-link-content {
  float: right; }

.event-single .event-location {
  margin: 15px 0 20px 0;
  color: #222;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  font-size: 15px;
  font-weight: 700;
  font-style: italic; }
  .event-single .event-location .fa {
    color: #e0e0e0;
    font-size: 18px; }
  .event-single .event-location .loc-link {
    margin-left: 30px; }

.event-single .events-gallery-container {
  padding: 20px 60px; }

.event-single .wpcf7-not-valid-tip {
  display: none; }

.event-single .wpcf7-validation-errors {
  display: none !important; }

.event-single .wpcf7-response-output {
  border: none;
  margin: 0;
  padding: 0;
  color: #a3bec9;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  padding-top: 10px; }

.event-single .wpcf7-form.sent {
  padding-top: 20px; }
  .event-single .wpcf7-form.sent .c-table {
    display: none; }
  .event-single .wpcf7-form.sent .ajax-loader {
    display: none; }

.event-single .subscribe-email {
  background: rgba(255, 255, 255, 0.38);
  border: none;
  color: #fff;
  margin-right: 20px;
  max-width: 250px; }

.event-single input.wpcf7-not-valid {
  border: 2px solid #cb4a3c; }

/*--------------------------------------------------------------
## Blog list page
--------------------------------------------------------------*/
.blog-list .blog-list-wrap,
.blog-list .event-list-wrap,
.events-list .blog-list-wrap,
.events-list .event-list-wrap {
  padding: 30px 60px; }

.blog-list .list-title a,
.events-list .list-title a {
  color: #070a09;
  font-size: 20px;
  font-weight: 700; }
  .blog-list .list-title a:hover,
  .events-list .list-title a:hover {
    text-decoration: underline; }

.blog-list .thumb-img,
.events-list .thumb-img {
  background: no-repeat center center;
  background-size: cover;
  height: 160px; }

.blog-list .blog-date a,
.blog-list .event-date a,
.events-list .blog-date a,
.events-list .event-date a {
  color: #222;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  font-size: 15px;
  font-weight: 700;
  font-style: italic; }
  .blog-list .blog-date a:hover,
  .blog-list .event-date a:hover,
  .events-list .blog-date a:hover,
  .events-list .event-date a:hover {
    text-decoration: underline; }
  .blog-list .blog-date a:after,
  .blog-list .event-date a:after,
  .events-list .blog-date a:after,
  .events-list .event-date a:after {
    margin-top: 20px;
    margin-bottom: 20px;
    content: '';
    display: block;
    width: 70px;
    height: 3px;
    background-color: #525252; }

.blog-list .blog-date .fa,
.blog-list .event-date .fa,
.events-list .blog-date .fa,
.events-list .event-date .fa {
  color: #00b9fc; }

.blog-list .blog-excerpt,
.blog-list .event-excerpt,
.events-list .blog-excerpt,
.events-list .event-excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 40px;
  margin-bottom: 10px; }

.blog-list .blog-item,
.blog-list .event-item,
.events-list .blog-item,
.events-list .event-item {
  margin-bottom: 30px; }

/*--------------------------------------------------------------
## Gallery post
--------------------------------------------------------------*/
.gallery-title-wrap .single-title {
  float: left; }
  .gallery-title-wrap .single-title a {
    color: #222; }

.gallery-title-wrap .date {
  float: right;
  margin-top: 20px;
  color: #525252;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  font-size: 15px;
  font-weight: 700;
  font-style: italic; }
  .gallery-title-wrap .date .fa {
    margin-right: 6px;
    color: #cadde6;
    font-size: 18px; }

.gallery-list-wrap .single-content-wrap:after {
  content: '';
  display: block;
  width: 70px;
  height: 3px;
  background-color: #525252; }

.gallery-sidebar-title {
  color: #cacaca;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px; }

.gallery-items {
  margin-top: 20px; }
  .gallery-items .g-image-wrap {
    width: 100%;
    height: 218px;
    margin-bottom: 30px;
    background: no-repeat center center;
    background-size: cover; }

.gallery-slider-wrap .gallery-slider-title {
  float: left;
  display: block;
  color: #cacaca;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase; }

.gallery-slider-wrap .gallery-link {
  display: block;
  float: right;
  line-height: 70px;
  color: #00b9fc;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  font-size: 14px;
  font-weight: 700;
  font-style: italic; }

.gallery-slider {
  position: relative;
  margin-bottom: 40px; }
  .gallery-slider .gallery-slider-item {
    height: 160px;
    display: block;
    background: no-repeat center center;
    background-size: cover; }
  .gallery-slider .owl-dots {
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 20px; }
    .gallery-slider .owl-dots .owl-dot {
      background-color: #ebf5f8;
      margin-left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      display: inline-block; }
      .gallery-slider .owl-dots .owl-dot.active {
        background-color: #4e9efe; }
  .gallery-slider .owl-prev,
  .gallery-slider .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.55;
    background-color: white;
    line-height: 160px;
    width: 25px;
    text-align: center; }
    .gallery-slider .owl-prev:hover,
    .gallery-slider .owl-next:hover {
      opacity: 0.8;
      transition: opacity .6s; }
    .gallery-slider .owl-prev .fa,
    .gallery-slider .owl-next .fa {
      color: black;
      font-size: 32px;
      font-weight: 400;
      line-height: 160px; }
  .gallery-slider .owl-prev {
    left: 0; }
  .gallery-slider .owl-next {
    right: 0; }

/*--------------------------------------------------------------
## Donate page
--------------------------------------------------------------*/
.donation-page,
.default-page {
  background: url("../images/lblue-white-bg.svg") repeat-y;
  background-size: 100%; }
  .donation-page .site-logo-wrap,
  .default-page .site-logo-wrap {
    padding-top: 30px;
    padding-bottom: 0; }
  .donation-page .donate-sidebar-wrap,
  .donation-page .default-sidebar-wrap,
  .default-page .donate-sidebar-wrap,
  .default-page .default-sidebar-wrap {
    background: #ebf5f8;
    min-height: 450px; }
  .donation-page .donate-content-wrap,
  .donation-page .default-content-wrap,
  .default-page .donate-content-wrap,
  .default-page .default-content-wrap {
    background: #fff;
    min-height: 450px;
    padding-bottom: 30px; }
  .donation-page .page-title,
  .default-page .page-title {
    color: #9faab0;
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
    text-align: right;
    transform: rotate(-90deg);
    transform-origin: 100% 100%; }
  .donation-page .page-title-wrap,
  .default-page .page-title-wrap {
    padding-right: 20px; }

.donation-page .donate-form-wrap {
  padding: 62px 96px 0;
  margin-bottom: 50px; }

.donation-page .form-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 33px; }

.donation-page .donate-form {
  background-color: #f7f7f7;
  padding: 25px 30px;
  position: relative; }
  .donation-page .donate-form .donate-fields .tRow {
    margin-bottom: 10px; }
  .donation-page .donate-form .donate-fields .tCell {
    display: inline-block; }
  .donation-page .donate-form .donate-fields .form-label {
    width: 150px; }
  .donation-page .donate-form .paypal-img {
    float: right; }
  .donation-page .donate-form .paypal-img-mobile {
    display: none; }
  .donation-page .donate-form .donate-action {
    position: absolute;
    left: 50%;
    margin-left: -68px; }
    .donation-page .donate-form .donate-action .donate-submit {
      border: 6px solid #fff;
      height: 62px;
      line-height: 53px;
      border-radius: 34px; }

.donation-page .donate-other {
  font-weight: 700;
  margin-top: 30px;
  padding: 25px 96px 25px 96px;
  background-color: #f7f7f7; }
  .donation-page .donate-other .fa {
    color: #d0d9de; }
  .donation-page .donate-other .other-title {
    color: #9faab0;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 17px; }
  .donation-page .donate-other .tel a {
    color: #545454; }

.donation-page .donate-entry {
  padding: 10px 96px; }

.default-page .default-entry {
  padding: 30px 60px; }

/*--------------------------------------------------------------
## About page
--------------------------------------------------------------*/
.about-page .site-logo-wrap {
  padding-bottom: 30px; }

.about-page .page-description:after {
  margin-top: 30px;
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background: #a3bdc9; }

.about-page .contacts-wrap {
  padding-top: 18px; }

.about-page .contact-label {
  margin-top: 20px;
  color: #a9b2b6;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  font-size: 14px;
  font-weight: 700;
  font-style: italic; }

.about-page .address-content {
  color: #070a09;
  font-size: 14px;
  font-weight: 700; }
  .about-page .address-content .address-link {
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    display: block; }

.about-page .tel-content a {
  color: #070a09;
  font-size: 14px;
  font-weight: 700; }

.about-page .email a {
  color: #00b9fc;
  font-size: 14px;
  font-weight: 700; }

.about-page .entry {
  padding: 30px 60px; }
  .about-page .entry .about-title {
    color: #222;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px; }
    .about-page .entry .about-title:after {
      margin-top: 24px;
      content: '';
      display: block;
      width: 70px;
      height: 3px;
      background: #222; }

.about-page .col-img {
  padding: 0; }
  .about-page .col-img img {
    width: 100%; }

.about-page .full-img-text {
  margin-top: 60px;
  padding-right: 40px; }
  .about-page .full-img-text:before {
    margin-bottom: 28px;
    content: '';
    display: block;
    width: 70px;
    height: 3px;
    background: #222; }

.about-page .img-section {
  background: #fff; }

.about-page .links-wrap {
  background: #fff; }

.about-page .links-bg-wrapper {
  padding-top: 60px;
  background: #fff; }

.about-page .links-title {
  font-weight: 700;
  font-size: 80px;
  line-height: 1;
  color: rgba(160, 160, 160, 0.26);
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  text-align: right; }

.about-page .link-item-entry {
  padding-bottom: 50px;
  padding-right: 30px; }
  .about-page .link-item-entry .link-title {
    color: #525252;
    font-size: 15px;
    font-weight: 700; }
  .about-page .link-item-entry .link {
    color: #00b9fc;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 15px;
    font-weight: 700;
    font-style: italic;
    padding: 20px 0;
    display: block; }
    .about-page .link-item-entry .link:after {
      margin-top: 24px;
      content: '';
      display: block;
      width: 70px;
      height: 3px;
      background: #222; }
  .about-page .link-item-entry .content {
    color: #515151;
    font-size: 15px;
    font-weight: 300; }

.about-page .about-footer {
  background: #fff; }

.about-page .waiting-for-you {
  background: #fff;
  min-height: 220px;
  padding-bottom: 55px; }

.about-page .waiting-for-you-wrap {
  border-top: 3px solid #f1f1f1;
  border-bottom: 3px solid #f1f1f1;
  padding: 37px 30px 45px 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px; }
  .about-page .waiting-for-you-wrap .youth-assoc {
    color: #bdbdbd;
    font-family: "Playfair Display", serif;
    font-size: 26px;
    font-weight: 900;
    font-style: italic; }
  .about-page .waiting-for-you-wrap .waiting-text {
    color: #5f5f5f;
    font-size: 38px;
    font-weight: 700;
    text-transform: uppercase; }
  .about-page .waiting-for-you-wrap .social-block {
    padding-top: 20px; }
    .about-page .waiting-for-you-wrap .social-block .social-links li a {
      background: #00b9fc;
      color: #fff;
      margin-right: 15px; }

.mission-page .entry {
  padding: 30px 60px; }
  .mission-page .entry .about-title {
    margin-bottom: 30px; }
  .mission-page .entry .title {
    color: #222;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase; }
    .mission-page .entry .title:after {
      margin-top: 24px;
      content: '';
      display: block;
      width: 70px;
      height: 3px;
      background: #222; }
  .mission-page .entry .short-entry {
    color: #949494;
    font-size: 14px;
    font-weight: 300; }

.mission-page .col-img {
  padding: 0;
  background: #fff; }

.mission-page .full-img-text {
  padding-right: 40px; }
  .mission-page .full-img-text:before {
    margin-bottom: 28px;
    content: '';
    display: block;
    width: 70px;
    height: 3px;
    background: #222; }

.mission-page .img-section {
  background: #fff;
  position: relative; }
  .mission-page .img-section img {
    position: relative; }

.mission-page .blue-square {
  width: 217px;
  height: 217px;
  background-color: #ebf5f8;
  position: absolute;
  top: -60px;
  right: 80px; }

.mission-page .blue-square-2 {
  bottom: 40px;
  right: 38%;
  top: auto; }

.mission-page .links-wrap {
  background: #fff; }

.mission-page .links-bg-wrapper {
  padding-top: 60px;
  background: #fff; }

.mission-page .links-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 1;
  color: rgba(160, 160, 160, 0.26);
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  text-align: right; }

.mission-page .link-item-entry {
  padding-bottom: 50px;
  padding-right: 30px; }
  .mission-page .link-item-entry .link-title {
    color: #00b9fc;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 15px;
    font-style: italic; }
  .mission-page .link-item-entry .content {
    color: #515151;
    font-size: 15px;
    font-weight: 300; }

.mission-page .about-footer {
  background: #fff; }

.mission-page .waiting-for-you {
  background: #fff;
  min-height: 220px;
  padding-bottom: 55px; }

.mission-page .waiting-for-you-wrap {
  border-top: 3px solid #f1f1f1;
  border-bottom: 3px solid #f1f1f1;
  padding: 37px 30px 45px 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px; }
  .mission-page .waiting-for-you-wrap .youth-assoc {
    color: #bdbdbd;
    font-family: "Playfair Display", serif;
    font-size: 26px;
    font-weight: 900;
    font-style: italic; }
  .mission-page .waiting-for-you-wrap .waiting-text {
    color: #5f5f5f;
    font-size: 38px;
    font-weight: 700;
    text-transform: uppercase; }
  .mission-page .waiting-for-you-wrap .social-block {
    padding-top: 20px; }
    .mission-page .waiting-for-you-wrap .social-block .social-links li a {
      background: #00b9fc;
      color: #fff;
      margin-right: 15px; }

.mission-page .site-logo-wrap {
  padding-bottom: 30px; }

.mission-page .page-title {
  min-height: 280px; }
  .mission-page .page-title h1 {
    transform: rotate(-90deg);
    transform-origin: 100% 100%; }

/*--------------------------------------------------------------
## Home page
--------------------------------------------------------------*/
.home-single .col-content,
.home-single .mobile-head,
.home-single .single-sidebar-wrap {
  height: 480px; }

.home-single .slider-wrap {
  height: 487px;
  overflow-y: hidden; }
  .home-single .slider-wrap .top-slider {
    height: 480px;
    overflow-y: hidden;
    position: relative; }
  .home-single .slider-wrap .owl-item {
    height: 480px; }
  .home-single .slider-wrap .slide-top-item {
    height: 480px;
    overflow-y: hidden;
    background: no-repeat center center;
    background-size: cover; }
  .home-single .slider-wrap .owl-controls {
    bottom: 0;
    position: absolute; }
  .home-single .slider-wrap .owl-dots {
    text-align: center;
    width: 100%;
    padding: 27px 12px; }
    .home-single .slider-wrap .owl-dots .owl-dot {
      background-color: #ebf5f8;
      margin-left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      display: inline-block; }
      .home-single .slider-wrap .owl-dots .owl-dot.active {
        background-color: #4e9efe; }

.home-single .col-content {
  position: relative; }

.home-single .mission-block-wrap {
  position: absolute;
  bottom: 80px;
  z-index: 2;
  background-color: rgba(0, 185, 252, 0.92);
  width: 350px;
  padding: 25px;
  left: -95px;
  padding-bottom: 50px; }
  .home-single .mission-block-wrap .mission-text {
    color: white;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 18px;
    font-style: italic; }
    .home-single .mission-block-wrap .mission-text:after {
      margin-top: 20px;
      width: 130px;
      height: 2px;
      content: '';
      display: block;
      background: rgba(255, 255, 255, 0.2); }
  .home-single .mission-block-wrap .mission-link {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 25px;
    margin-bottom: 25px;
    padding-left: 30px; }

.home-single .section-title-wrap .section-title {
  font-style: italic;
  font-size: 24px;
  font-weight: 700;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
  text-align: right;
  font-family: "Playfair Display", serif; }

.home-single .home-events {
  background: url("../images/gray-blue-bg.svg") repeat-y;
  background-size: 101%; }
  .home-single .home-events .events-content-wrap {
    background: #fff;
    padding: 0; }
  .home-single .home-events .section-title {
    color: rgba(255, 255, 255, 0.68); }
  .home-single .home-events .event-item {
    width: 50%;
    height: 380px;
    float: left; }
    .home-single .home-events .event-item.has-thumb .event-img {
      width: 50%;
      float: left; }
    .home-single .home-events .event-item.has-thumb .event-item-entry {
      width: 50%;
      float: left; }
    .home-single .home-events .event-item.has-thumb.event-item-3 .event-img {
      float: right; }
    .home-single .home-events .event-item.has-thumb.event-item-3 .event-item-entry {
      float: left; }
    .home-single .home-events .event-item .event-img {
      background: no-repeat center center;
      background-size: cover;
      height: 380px; }
      .home-single .home-events .event-item .event-img img {
        display: none; }
    .home-single .home-events .event-item .event-item-entry {
      padding: 20px;
      position: relative;
      height: 380px;
      overflow-y: hidden; }
      .home-single .home-events .event-item .event-item-entry .event-date {
        color: #a3bdca;
        font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
        font-size: 15px;
        font-weight: 700; }
      .home-single .home-events .event-item .event-item-entry .event-title {
        color: #272727;
        font-size: 20px;
        font-weight: 700; }
      .home-single .home-events .event-item .event-item-entry .event-location {
        color: #00b9fc;
        font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
        font-size: 14px;
        font-weight: 700;
        font-style: italic;
        padding-top: 20px; }
      .home-single .home-events .event-item .event-item-entry .event-excerpt {
        margin-top: 20px;
        color: #cacaca;
        font-size: 15px;
        font-weight: 400;
        max-height: 84px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; }
        .home-single .home-events .event-item .event-item-entry .event-excerpt:before {
          margin-bottom: 20px;
          content: '';
          display: block;
          width: 70px;
          height: 3px;
          background-color: #525252; }
      .home-single .home-events .event-item .event-item-entry .event-link {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 30px 20px 20px;
        background: #fff; }
  .home-single .home-events .event-item-info-block {
    background-color: #ebf5f8;
    text-align: center;
    padding: 100px 50px 0 50px; }
    .home-single .home-events .event-item-info-block .site-name {
      color: #afafaf;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 25px;
      font-weight: 700;
      font-style: italic; }
    .home-single .home-events .event-item-info-block .event-link-block-title {
      margin-top: 22px;
      color: black;
      font-size: 40px;
      font-weight: 700;
      line-height: 44px;
      text-transform: uppercase; }
    .home-single .home-events .event-item-info-block .view-more-events {
      margin-top: 35px; }

.home-single .make-donation-section {
  padding: 40px 0; }
  .home-single .make-donation-section.make-donation-section-first {
    background-color: #11d4eb; }
  .home-single .make-donation-section.make-donation-section-second {
    background-color: #00b9fc; }
  .home-single .make-donation-section .site-name {
    color: rgba(239, 240, 240, 0.42);
    font-family: "Playfair Display", serif;
    font-size: 25px;
    font-weight: 900;
    font-style: italic; }
  .home-single .make-donation-section .donation-title {
    color: white;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    text-transform: uppercase; }
  .home-single .make-donation-section .donate-link {
    margin-top: 16px; }
    .home-single .make-donation-section .donate-link a {
      color: #11d4eb; }
  .home-single .make-donation-section .donate-text {
    margin-top: 7px;
    color: #f0f0f0;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px; }

.home-single .gallery-section .gallery-left {
  width: 50%;
  float: left;
  height: 662px;
  background: #fff; }
  .home-single .gallery-section .gallery-left .gallery-left-small {
    width: 50%;
    float: left;
    height: 50%;
    overflow: hidden; }

.home-single .gallery-section .gallery-right {
  height: 662px;
  width: 50%;
  float: left;
  overflow: hidden;
  background: #fff; }
  .home-single .gallery-section .gallery-right .gallery-right-big {
    height: 100%; }

.home-single .gallery-section .gallery-left-small-first-bg {
  background: no-repeat center center;
  background-size: cover;
  height: 100%;
  text-align: center;
  padding: 100px 15px 0 15px; }
  .home-single .gallery-section .gallery-left-small-first-bg .text-first {
    color: #727272;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 25px;
    font-weight: 700;
    font-style: italic; }
  .home-single .gallery-section .gallery-left-small-first-bg .text-second {
    margin-top: 10px;
    color: black;
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    text-transform: uppercase; }

.home-single .gallery-section .gallery-left-small-nav {
  position: relative;
  height: 100%; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-nav .left-slide,
  .home-single .gallery-section .gallery-left-small-nav .gallery-nav .right-slide {
    width: 95px;
    height: 100px;
    background-color: #c6c6c6;
    color: #fff;
    text-align: center;
    display: inline-block;
    transition: all .6s;
    cursor: pointer; }
    .home-single .gallery-section .gallery-left-small-nav .gallery-nav .left-slide .fa,
    .home-single .gallery-section .gallery-left-small-nav .gallery-nav .right-slide .fa {
      font-size: 100px; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-nav .left-slide:hover {
    background-color: #959595;
    padding-right: 10px; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-nav .right-slide:hover {
    background-color: #959595;
    padding-left: 10px; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #bebebe;
    font-family: "Playfair Display", serif;
    font-size: 84px;
    font-style: italic;
    font-weight: 700;
    margin-bottom: -94px;
    transform: rotate(90deg);
    transform-origin: 100% 0; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-link {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 45px 34px; }

.home-single .gallery-section .gallery-item {
  height: 100%;
  position: relative; }

.home-single .gallery-section .gallery-item-1 .gallery-img {
  transition: opacity .8s; }

.home-single .gallery-section .gallery-item-2 .gallery-img {
  transition: opacity .5s; }

.home-single .gallery-section .gallery-item-3 .gallery-img {
  transition: opacity .3s; }

.home-single .gallery-section .gallery-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: no-repeat center center;
  background-size: cover; }
  .home-single .gallery-section .gallery-img.gallery-img-0 {
    opacity: 1; }
  .home-single .gallery-section .gallery-img.gallery-img-show {
    opacity: 1 !important; }
  .home-single .gallery-section .gallery-img.gallery-img-hidden {
    opacity: 0 !important; }

.home-single .subscribe-section {
  background-color: #1cd6d4;
  padding-top: 25px;
  padding-bottom: 35px;
  /* Firefox 19+ */
  /* Firefox 18- */ }
  .home-single .subscribe-section .subscribe-text-wrap {
    border-right: 1px solid rgba(255, 255, 255, 0.35);
    padding-right: 70px; }
  .home-single .subscribe-section .subscribe-site {
    color: rgba(239, 240, 240, 0.41);
    font-family: "Playfair Display", serif;
    font-size: 28px;
    font-weight: 900;
    font-style: italic;
    line-height: 35px; }
  .home-single .subscribe-section .subscribe-text {
    color: white;
    font-size: 44px;
    font-weight: 700;
    line-height: 46px;
    text-transform: uppercase; }
  .home-single .subscribe-section .subscribe-text-1 {
    color: white;
    font-size: 27px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase; }
  .home-single .subscribe-section .subscribe-form-wrap {
    padding-left: 60px; }
  .home-single .subscribe-section .wpcf7-form {
    padding-top: 30px; }
  .home-single .subscribe-section .wpcf7-form.sent {
    padding-top: 20px; }
    .home-single .subscribe-section .wpcf7-form.sent .ajax-loader {
      display: none; }
    .home-single .subscribe-section .wpcf7-form.sent .subscribe-email,
    .home-single .subscribe-section .wpcf7-form.sent .subscribe-submit {
      display: none; }
  .home-single .subscribe-section .subscribe-email {
    background: rgba(255, 255, 255, 0.38);
    border: none;
    color: #fff;
    margin-right: 20px;
    max-width: 250px; }
    .home-single .subscribe-section .subscribe-email.wpcf7-not-valid {
      border: 2px solid #cb4a3c; }
  .home-single .subscribe-section .wpcf7-not-valid-tip {
    display: none; }
  .home-single .subscribe-section .wpcf7-validation-errors {
    display: none !important; }
  .home-single .subscribe-section .subscribe-submit {
    padding: 0 60px;
    outline: none;
    color: #1cd6d4; }
  .home-single .subscribe-section .wpcf7-response-output {
    border: none;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    padding-top: 10px; }
  .home-single .subscribe-section ::-webkit-input-placeholder {
    color: #fff; }
  .home-single .subscribe-section ::-moz-placeholder {
    color: #fff; }
  .home-single .subscribe-section :-moz-placeholder {
    color: #fff; }
  .home-single .subscribe-section :-ms-input-placeholder {
    color: #fff; }

.home-single .blog-section {
  background: #ebf5f8; }
  .home-single .blog-section .blog-section-title {
    color: #a3bec9;
    font-family: "Playfair Display", serif;
    margin-left: 30px;
    font-weight: 700;
    line-height: 15px;
    font-style: italic;
    font-size: 64px;
    transform: rotate(90deg);
    transform-origin: 0 100%; }
  .home-single .blog-section .blog-item {
    height: 320px;
    overflow: hidden; }
    .home-single .blog-section .blog-item .blog-img {
      height: 320px;
      background: no-repeat center center;
      background-size: cover; }
      .home-single .blog-section .blog-item .blog-img img {
        display: none; }
    .home-single .blog-section .blog-item.has-thumb .blog-img,
    .home-single .blog-section .blog-item.has-thumb .blog-entry {
      width: 50%;
      float: left; }
    .home-single .blog-section .blog-item.blog-item-2 .blog-img {
      float: right; }
    .home-single .blog-section .blog-item.blog-item-3 .blog-entry {
      background: #ebf5f8; }
    .home-single .blog-section .blog-item.blog-item-3 .blog-more {
      background: #ebf5f8; }
      .home-single .blog-section .blog-item.blog-item-3 .blog-more .read-more {
        border-color: #cad2d5; }
    .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap {
      width: 50%;
      float: left;
      background: #fff;
      height: 320px;
      padding: 55px 30px;
      text-align: center; }
      .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .site-name {
        color: #afafaf;
        font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
        font-size: 25px;
        font-weight: 700;
        font-style: italic; }
      .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .link-title {
        margin-top: 18px;
        margin-bottom: 35px;
        color: black;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        text-transform: uppercase; }
    .home-single .blog-section .blog-item .blog-entry {
      background: #fff;
      padding: 28px 64px;
      height: 320px;
      overflow: hidden;
      position: relative; }
    .home-single .blog-section .blog-item .blog-date {
      color: #00b9fc;
      font-style: italic;
      font-size: 15px;
      font-weight: 700;
      text-align: right; }
    .home-single .blog-section .blog-item .blog-title a {
      color: #070a09;
      font-size: 20px;
      font-weight: 700; }
    .home-single .blog-section .blog-item .blog-content {
      padding-top: 15px;
      color: #888;
      font-size: 15px;
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 100px; }
    .home-single .blog-section .blog-item .blog-more {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #fff;
      padding-left: 64px;
      padding-bottom: 28px;
      padding-top: 10px; }

.home-single .about-us-section {
  background: url("../images/gray-white-bg.svg") repeat-y;
  background-size: 101%; }
  .home-single .about-us-section .about-side-top,
  .home-single .about-us-section .about-side-btm {
    padding-right: 0; }
  .home-single .about-us-section .about-side-top .about-title {
    color: white;
    font-family: "Playfair Display", serif;
    font-size: 36px;
    font-weight: 700;
    font-style: italic;
    text-align: right;
    margin-right: 30px;
    margin-top: -20px;
    transform: rotate(-90deg);
    transform-origin: 100% 100%; }
  .home-single .about-us-section .about-side-btm {
    height: 230px;
    position: relative; }
    .home-single .about-us-section .about-side-btm img {
      height: 100%;
      max-width: none;
      position: absolute;
      right: 0;
      top: 0; }
  .home-single .about-us-section .about-content-top {
    background: #fff; }
    .home-single .about-us-section .about-content-top .site-logo {
      margin: 72px 100px 42px 32px; }
    .home-single .about-us-section .about-content-top .join-text {
      margin-top: 71px;
      margin-bottom: 23px;
      color: #9aa7ac;
      font-size: 15px;
      font-weight: 400; }
    .home-single .about-us-section .about-content-top .join-link-wrap {
      margin-bottom: 30px; }
  .home-single .about-us-section .about-content-btm {
    background-color: #e0f1f7;
    height: 230px; }
    .home-single .about-us-section .about-content-btm .about-text {
      margin: 38px 50px 25px 94px;
      color: #9aa7ac;
      font-size: 15px;
      line-height: 18px; }
    .home-single .about-us-section .about-content-btm .about-link {
      margin-left: 94px; }
    .home-single .about-us-section .about-content-btm .contacts-wrap {
      padding-top: 18px; }
    .home-single .about-us-section .about-content-btm .contact-label {
      margin-top: 20px;
      color: #a9b2b6;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 14px;
      font-weight: 700;
      font-style: italic; }
    .home-single .about-us-section .about-content-btm .address-content {
      color: #070a09;
      font-size: 14px;
      font-weight: 700; }
      .home-single .about-us-section .about-content-btm .address-content .address-link {
        font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
        font-size: 14px;
        font-weight: 700;
        font-style: italic;
        margin-left: 20px; }
    .home-single .about-us-section .about-content-btm .tel-content a {
      color: #070a09;
      font-size: 14px;
      font-weight: 700; }
    .home-single .about-us-section .about-content-btm .email a {
      color: #00b9fc;
      font-size: 14px;
      font-weight: 700; }

.home-single .video-section {
  background: #fff;
  padding-top: 48px;
  padding-bottom: 43px; }
  .home-single .video-section .video-entry {
    padding-right: 40px; }
    .home-single .video-section .video-entry .title {
      color: #616161;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 28px; }
      .home-single .video-section .video-entry .title:after {
        display: block;
        content: '';
        width: 70px;
        height: 3px;
        background-color: #525252;
        margin-top: 26px; }
    .home-single .video-section .video-entry .video-text {
      color: #757575;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 43px; }

.home-single .footer-section {
  background: url("../images/lblue-gray-bg.svg") repeat-y;
  background-size: 101%; }
  .home-single .footer-section .wpcf7-not-valid-tip {
    display: none; }
  .home-single .footer-section .wpcf7-not-valid {
    border-color: red; }
  .home-single .footer-section .wpcf7-validation-errors {
    display: none !important; }
  .home-single .footer-section .wpcf7-form.sent .send-msg-wrap {
    display: none; }
  .home-single .footer-section .wpcf7-form.sent .wpcf7-response-output {
    border: none;
    color: #57666d;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    padding: 0 100px 0 0; }
  .home-single .footer-section .form-title {
    color: #a3bec9;
    font-family: "Playfair Display", serif;
    font-size: 33px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 27px; }
  .home-single .footer-section .name-row input {
    width: 100%; }
  .home-single .footer-section .subj-row {
    margin-top: 12px; }
    .home-single .footer-section .subj-row input {
      width: 100%; }
  .home-single .footer-section .msg-row {
    margin-top: 12px; }
    .home-single .footer-section .msg-row .submit-main-form {
      vertical-align: top;
      width: 37%;
      border: none; }
    .home-single .footer-section .msg-row textarea {
      max-width: 60%;
      border-radius: 0;
      border: none;
      background: #ebf5f8;
      border-bottom: 2px solid #fff;
      resize: none;
      max-height: 80px; }
      .home-single .footer-section .msg-row textarea.wpcf7-not-valid {
        border-color: red; }
  .home-single .footer-section .social-footer-block {
    background: #fff;
    height: 312px;
    text-align: center;
    padding-top: 80px; }
    .home-single .footer-section .social-footer-block .site-name {
      color: #afafaf;
      font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
      font-size: 25px;
      font-weight: 700;
      font-style: italic; }
    .home-single .footer-section .social-footer-block .come-join {
      margin-top: 21px;
      color: black;
      font-size: 40px;
      font-weight: 700;
      line-height: 44px;
      text-transform: uppercase; }
    .home-single .footer-section .social-footer-block .social-footer-icons {
      margin-top: 31px; }
      .home-single .footer-section .social-footer-block .social-footer-icons .social-links li {
        background: #00b9fc;
        margin-right: 12px; }
        .home-single .footer-section .social-footer-block .social-footer-icons .social-links li a {
          color: #fff; }

.amazon-section {
  padding: 40px 0;
  background: #fe9b00; }
  .amazon-section .amazon-logo img {
    max-width: 300px; }
  .amazon-section .amazon-link {
    margin-top: 12px; }
    .amazon-section .amazon-link a {
      color: #fe9b00; }
  .amazon-section .amazon-text {
    color: #fff;
    margin-top: 16px; }

/*--------------------------------------------------------------
## Corrections for Russian lang
--------------------------------------------------------------*/
.page-ru-lang .events-content-title .section-title-wrap {
  margin-top: 60px; }

.page-ru-lang .home-events .event-item-info-block .site-name {
  font-size: 20px; }

.page-ru-lang .home-single .gallery-section .gallery-left-small-nav .gallery-title {
  font-size: 60px;
  margin-bottom: -50px;
  margin-right: -15px; }

.page-ru-lang .home-single .subscribe-section .subscribe-site {
  font-size: 22px; }

.page-ru-lang .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .site-name {
  font-size: 20px; }

.page-ru-lang .home-single .footer-section .social-footer-block {
  padding-top: 50px; }

.page-ru-lang .site-logo .site-title {
  font-size: 18px;
  padding-top: 20px; }

.page-ru-lang .slogan-wrap .slogan-site-text {
  font-size: 24px; }

.page-ru-lang .gallery-section .gallery-left-small-first-bg .text-second {
  font-size: 32px; }

.page-ru-lang .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .link-title {
  font-size: 32px; }

@media (max-width: 1200px) {
  .page-ru-lang .site-logo .site-title {
    font-size: 16px;
    padding-top: 16px; }
  .page-ru-lang .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    font-size: 48px;
    margin-right: 10px;
    margin-bottom: 35px; }
  .page-ru-lang .home-single .gallery-section .gallery-left-small-nav .gallery-link a {
    font-size: 12px; }
  .page-ru-lang .home-single .subscribe-section .subscribe-site {
    font-size: 20px; }
  .page-ru-lang .home-single .subscribe-section .subscribe-text {
    font-size: 34px; }
  .page-ru-lang .home-single .subscribe-section .subscribe-text-wrap {
    padding-right: 30px; }
  .page-ru-lang .home-single .subscribe-section .subscribe-form-wrap {
    padding-left: 30px; }
  .page-ru-lang .home-single .subscribe-section .subscribe-submit {
    padding: 0 30px; }
  .page-ru-lang .page-title h1 {
    font-size: 28px; } }

@media (max-width: 992px) {
  .page-ru-lang .site-logo .site-title {
    font-size: 14px;
    padding-top: 2px; }
  .page-ru-lang .event-link .read-more {
    font-size: 10px; }
  .page-ru-lang .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    font-size: 36px; }
  .page-ru-lang .home-single .gallery-section .gallery-left-small-nav .gallery-link a {
    font-size: 9px; }
  .page-ru-lang .page-title h1 {
    font-size: 24px; }
  .page-ru-lang .slogan-wrap .slogan-site-text {
    font-size: 14px; } }

@media (max-width: 767px) {
  .page-ru-lang .event-link .read-more {
    font-size: 14px; }
  .page-ru-lang .home-single .subscribe-section .subscribe-form-wrap {
    padding-left: 0; }
  .page-ru-lang .home-single .subscribe-section .subscribe-text-wrap {
    padding-right: 0; }
  .page-ru-lang .top-header-wrap .mobile-site-logo {
    width: 170px; }
    .page-ru-lang .top-header-wrap .mobile-site-logo .site-title {
      padding-top: 0; } }

@media (max-width: 425px) {
  .page-ru-lang .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    font-size: 32px;
    padding-left: 5px; }
  .page-ru-lang .home-single .gallery-section .gallery-left-small-nav .gallery-link {
    padding: 10px; }
    .page-ru-lang .home-single .gallery-section .gallery-left-small-nav .gallery-link a {
      font-size: 9px; } }

.directors-fields {
  padding: 30px 60px; }
  .directors-fields .director-item {
    padding: 30px 0;
    border-bottom: 1px solid rgba(145, 145, 145, 0.15); }
  .directors-fields .director-content .name {
    margin: 0;
    color: #272727;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px; }
  .directors-fields .director-content .position {
    margin-bottom: 10px;
    color: #717171;
    font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
    font-size: 15px;
    font-weight: 700;
    font-style: italic; }

#up-arrow {
  position: fixed;
  font-size: 28px;
  color: #9faab0;
  bottom: 100px;
  z-index: 2;
  right: 50px;
  padding: 10px 16px;
  border-radius: 5px;
  background: rgba(223, 233, 236, 0.59);
  transition: all .6s;
  display: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.24); }
  #up-arrow:hover {
    background: #9faab0;
    color: #dfe9ec; }

.fixed-header #up-arrow {
  display: block; }

.gray-text-block {
  padding: 30px 0;
  color: #939393;
  font-size: 24px;
  border-top: 3px solid rgba(82, 82, 82, 0.17);
  border-bottom: 3px solid rgba(82, 82, 82, 0.17);
  font-weight: 300;
  line-height: 28px;
  margin: 20px 0 45px; }

@media (max-width: 1200px) {
  .top-header-wrap .social-links-wrap {
    padding-top: 24px;
    padding-left: 10px; }
  .top-header-wrap .social-links li {
    margin-right: 5px; }
    .top-header-wrap .social-links li a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 20px; }
  .site-logo .site-title {
    font-size: 16px;
    margin-left: 148px; }
  .home-single .gallery-section .gallery-left-small-first-bg .text-second {
    font-size: 28px; }
  .home-single .about-us-section .about-content-top {
    background: #fff; }
  /*--------------------------------------------------------------
  ## Donate page
  --------------------------------------------------------------*/
  .donation-page .donate-form .donate-fields .form-label {
    display: block;
    margin-bottom: 20px; }
  .donation-page .donate-form .donate-fields .tCell {
    padding-bottom: 10px; }
  .organizers-wrap .organizer-info {
    padding: 0; }
    .organizers-wrap .organizer-info .organizer-name {
      font-size: 18px;
      line-height: 20px; }
  .sidebar-event-wrap {
    font-size: 12px; }
  .home-single .home-events .event-item-info-block {
    padding-top: 50px; }
  .home-single .make-donation-section .donation-title {
    font-size: 32px; }
  .home-single .make-donation-section .donate-text {
    padding-left: 15px; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-nav .left-slide,
  .home-single .gallery-section .gallery-left-small-nav .gallery-nav .right-slide {
    width: 70px;
    height: 70px; }
    .home-single .gallery-section .gallery-left-small-nav .gallery-nav .left-slide .fa,
    .home-single .gallery-section .gallery-left-small-nav .gallery-nav .right-slide .fa {
      font-size: 70px; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    font-size: 62px;
    margin-bottom: 10px; }
  .home-single .subscribe-section .subscribe-site {
    font-size: 24px; }
  .home-single .subscribe-section .subscribe-text-1 {
    font-size: 24px; }
  .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap {
    padding-top: 30px; }
  .home-single .about-us-section .about-content-btm,
  .home-single .about-us-section .about-side-btm {
    height: 250px; }
  .home-single .blog-section .blog-section-title {
    font-size: 52px;
    margin-left: 20px; }
  .about-page .waiting-for-you-wrap,
  .mission-page .waiting-for-you-wrap {
    padding: 25px; }
    .about-page .waiting-for-you-wrap .waiting-text,
    .mission-page .waiting-for-you-wrap .waiting-text {
      font-size: 30px; }
    .about-page .waiting-for-you-wrap .social-block .social-links li a,
    .mission-page .waiting-for-you-wrap .social-block .social-links li a {
      margin-right: 4px; }
  .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .link-title {
    font-size: 26px; } }

@media (max-width: 992px) {
  .top-header-wrap .social-links-wrap {
    padding-top: 30px;
    padding-left: 10px; }
  .top-header-wrap .social-links li {
    margin-right: 5px; }
    .top-header-wrap .social-links li a {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 16px; }
  .site-logo .site-title {
    padding-top: 0;
    margin-left: 110px; }
  .home-single .gallery-section .gallery-left-small-first-bg .text-second {
    font-size: 20px;
    line-height: 1.5; }
  .home-single .make-donation-section .site-name {
    text-align: center; }
  .home-single .make-donation-section .donation-title {
    text-align: center; }
  .home-single .make-donation-section .donate-link {
    text-align: center;
    margin-top: 0; }
  .home-single .make-donation-section .donate-text {
    text-align: center;
    padding-left: 0; }
  /*--------------------------------------------------------------
  ## Common styles
  --------------------------------------------------------------*/
  .gray-text-block {
    font-size: 18px;
    line-height: 22px; }
  .sidebar-blog-wrap ul li a {
    font-size: 16px; }
  .common-single .post-date .month-year,
  .common-single .post-date .weekday {
    font-size: 14px; }
  .common-single .post-date .day {
    margin-right: 0; }
  .common-single .single-content-wrap {
    padding: 30px; }
  .common-single .page-title h1 {
    font-size: 24px; }
  .common-single .event-program .c-table,
  .common-single .event-program .c-row,
  .common-single .event-program .c-cell,
  .common-single .order-ticket-form-wrap .c-table,
  .common-single .order-ticket-form-wrap .c-row,
  .common-single .order-ticket-form-wrap .c-cell {
    display: block; }
  .common-single .order-ticket-form-wrap input {
    margin-bottom: 15px;
    display: block; }
  /*--------------------------------------------------------------
  ## Blog list page
  --------------------------------------------------------------*/
  .blog-list .blog-date {
    margin-top: 15px; }
  .blog-list .blog-excerpt {
    margin-bottom: 15px; }
  /*--------------------------------------------------------------
  ## Donate page
  --------------------------------------------------------------*/
  .donation-page .donate-form .amount-title {
    display: inline-block;
    float: left;
    height: 50px;
    line-height: 50px;
    vertical-align: middle; }
  .donation-page .donate-form .paypal-img-mobile {
    display: inline-block;
    float: right; }
  .donation-page .donate-form .paypal-img {
    display: none; }
  .donation-page .donate-form .donate-fields .tCell {
    width: 100%; }
  .donation-page .donate-form .donate-fields .form-label {
    margin-bottom: 5px; }
  .donation-page .donate-form input,
  .donation-page .donate-form select {
    width: 100%; }
  .donation-page .donate-form .all-amount {
    margin-bottom: 10px; }
  .home-single .mobile-head {
    height: 400px; }
  .home-single .col-content {
    height: 400px; }
  .home-single .single-sidebar-wrap {
    height: 400px; }
  .home-single .slider-wrap {
    height: 400px; }
    .home-single .slider-wrap .top-slider {
      height: 400px; }
    .home-single .slider-wrap .owl-item {
      height: 400px; }
    .home-single .slider-wrap .slide-top-item {
      height: 400px; }
  .home-single .mission-block-wrap {
    padding: 15px 15px 55px;
    bottom: 60px;
    left: -120px; }
    .home-single .mission-block-wrap .mission-text {
      font-size: 16px; }
  .home-single .owl-carousel .owl-item img {
    min-height: 100%;
    min-width: 100%; }
  .home-single .events-content-title {
    background: #ebf5f8;
    padding: 10px 30px;
    border-top: 8px solid #00b9fc; }
  .home-single .section-title-wrap .section-title {
    transform: none;
    color: #a3bec9; }
  .home-single .make-donation-section .site-name {
    font-size: 20px; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    transform: none;
    position: relative;
    font-size: 42px;
    text-align: center;
    margin-top: 40px; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-link {
    padding: 40px 20px;
    right: 0;
    text-align: center; }
  .home-single .subscribe-section .subscribe-form-wrap {
    padding-left: 10px; }
  .home-single .subscribe-section .subscribe-email {
    max-width: 180px; }
  .home-single .subscribe-section .subscribe-submit {
    padding: 0 30px; }
  .home-single .subscribe-section .wpcf7-response-output {
    font-size: 24px;
    line-height: 1; }
  .home-single .blog-section .blog-item .blog-entry {
    padding: 25px 30px; }
  .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .link-title {
    margin-bottom: 15px;
    line-height: 1.5; }
  .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .site-name {
    font-size: 18px; }
  .home-single .blog-section .blog-section-title {
    transform: none;
    border-bottom: 8px solid #00b9fc;
    margin: 0;
    line-height: 1;
    padding: 20px 30px; }
  .home-single .about-us-section .about-side-top {
    background: #ebf5f8;
    border-bottom: 8px solid #00b9fc; }
    .home-single .about-us-section .about-side-top .about-title {
      transform: none;
      line-height: 1;
      margin: 0;
      padding: 20px 30px;
      color: #a3bec9; }
  .home-single .about-us-section .about-content-top .site-logo {
    margin-right: 70px; }
    .home-single .about-us-section .about-content-top .site-logo .site-title {
      margin-left: 130px;
      margin-top: 30px; }
  .home-single .about-us-section .about-side-btm {
    display: none; }
  .home-single .about-us-section .about-content-btm .about-text {
    margin-left: 30px;
    margin-right: 30px; }
  .home-single .footer-section .form-title {
    margin: 0;
    padding: 30px; }
  .home-single .footer-section .form-send-msg-wrap {
    background: #ebf5f8; }
  .home-single .footer-section .form-send-msg-wrap {
    padding: 30px; }
  .about-page .site-logo-wrap-col,
  .mission-page .site-logo-wrap-col {
    display: none; }
  .mission-page .img-section .blue-square {
    display: none; }
  .mission-page .img-section .img-text {
    padding-right: 0;
    background: #fff;
    padding-bottom: 40px; }
  .directors-fields .director-img {
    margin-bottom: 30px; }
  .amazon-section {
    text-align: center; }
    .amazon-section .amazon-text {
      margin-top: 20px; }
  .random-photos-wrap {
    margin-right: 0; }
    .random-photos-wrap .galleries-link {
      font-size: 12px; }
    .random-photos-wrap .photos-title {
      font-size: 14px; }
  .sidebar-blog-wrap {
    padding: 60px 0 0; }
    .sidebar-blog-wrap .blog-page-link a {
      font-size: 12px; }
  .sidebar-event-wrap {
    padding: 0; }
    .sidebar-event-wrap .event-page-link a {
      font-size: 12px; }
  .common-single .order-link-side {
    font-size: 12px; }
  .common-single .post-date .day {
    font-size: 72px; }
  .common-single .post-date .additional-date {
    padding-top: 10px; } }

@media (max-width: 767px) {
  .top-header-wrap {
    background: #fff; }
    .top-header-wrap .social-links-wrap {
      display: none; }
    .top-header-wrap .header-elements {
      height: 88px;
      text-align: left; }
      .top-header-wrap .header-elements .donate {
        padding-right: 10px; }
        .top-header-wrap .header-elements .donate .btn-blue, .top-header-wrap .header-elements .donate .event-single .order-ticket-form-wrap input[type="submit"], .event-single .order-ticket-form-wrap .top-header-wrap .header-elements .donate input[type="submit"] {
          width: 50px;
          padding: 0; }
        .top-header-wrap .header-elements .donate .donate-icon {
          display: block;
          width: 30px;
          margin-left: 10px;
          height: 50px; }
        .top-header-wrap .header-elements .donate .donate-text {
          display: none; }
      .top-header-wrap .header-elements .lang-switch {
        display: none; }
      .top-header-wrap .header-elements .menu-btn-wrap {
        float: right;
        padding-top: 12px;
        padding-left: 0; }
        .top-header-wrap .header-elements .menu-btn-wrap .menu-text {
          display: none; }
    .top-header-wrap .mobile-site-logo {
      display: inline-block;
      width: 170px;
      position: relative;
      text-align: left;
      padding-left: 10px; }
      .top-header-wrap .mobile-site-logo .site-title {
        text-align: left;
        display: inline-block;
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding-left: 65px;
        font-family: "Open Sans Condensed", sans-serif;
        font-weight: 500;
        padding-top: 10px; }
        .top-header-wrap .mobile-site-logo .site-title a {
          color: #555; }
  .mobile-head .single-sidebar-wrap {
    padding-bottom: 5px;
    border-bottom: 7px solid #68b8fa; }
  .page-title-wrap {
    float: right; }
  .post-date {
    float: left; }
  .site-logo-wrap {
    display: none; }
  .sidebar-blog-wrap {
    display: none; }
  .white-blue-bg {
    background: none; }
  .sintetic-bg-lblue {
    display: none !important; }
  .common-single .post-date .day {
    font-size: 80px; }
  .common-single .post-date .additional-date {
    padding-top: 20px; }
  .common-single .post-date .calendar-icon {
    margin-bottom: 3px; }
  .common-single .page-title {
    padding-right: 15px;
    padding-top: 20px; }
  .common-single .single-content-wrap {
    padding: 15px 30px; }
  .common-single .order-link-side {
    right: 0;
    margin-right: 30px; }
  .common-single .event-program {
    margin-top: 30px;
    margin-bottom: 30px; }
  .blog-list .page-title-wrap {
    width: 30%;
    text-align: right; }
    .blog-list .page-title-wrap .page-title {
      padding-top: 0; }
  .blog-list .page-description {
    width: 70%; }
  .blog-list .blog-list-wrap {
    padding: 15px 30px; }
  /*--------------------------------------------------------------
  ## Donate page
  --------------------------------------------------------------*/
  .donation-page,
  .default-page {
    background: none; }
    .donation-page .page-title,
    .default-page .page-title {
      text-align: left;
      padding-bottom: 12px; }
    .donation-page .mobile-head,
    .default-page .mobile-head {
      background: #ebf5f8;
      border-bottom: 7px solid #68b8fa; }
    .donation-page .site-logo-wrap,
    .default-page .site-logo-wrap {
      padding-top: 0; }
    .donation-page .site-logo,
    .default-page .site-logo {
      display: none; }
    .donation-page .page-title,
    .default-page .page-title {
      transform: none; }
    .donation-page .donate-sidebar-wrap,
    .donation-page .default-sidebar-wrap,
    .default-page .donate-sidebar-wrap,
    .default-page .default-sidebar-wrap {
      min-height: 10px; }
    .donation-page .donate-form-wrap,
    .default-page .donate-form-wrap {
      padding: 0;
      padding-top: 60px; }
    .donation-page .donate-entry,
    .donation-page .default-entry,
    .default-page .donate-entry,
    .default-page .default-entry {
      padding: 25px 10px; }
    .donation-page .donate-other,
    .default-page .donate-other {
      padding: 25px 10px; }
  .donation-page .form-title {
    margin-left: 30px; }
  .site-footer .footer-right,
  .site-footer .footer-left {
    text-align: center; }
  .site-footer .footer-left {
    margin-bottom: 15px; }
  .sidebar-event-wrap {
    display: none; }
  .event-single .order-ticket-form-wrap {
    padding: 30px; }
  .slogan-wrap {
    display: none; }
  #main-nav ul.lang-switch,
  #main-nav .social-links-wrap {
    display: block; }
  .home-single .mobile-head {
    display: none; }
  .home-single .mission-block-wrap {
    max-width: 90%;
    left: 40px;
    text-align: center;
    padding: 15px; }
    .home-single .mission-block-wrap .mission-text:after {
      display: none; }
    .home-single .mission-block-wrap .mission-link {
      position: relative;
      margin: 0;
      padding: 20px 0; }
      .home-single .mission-block-wrap .mission-link a {
        width: 100%; }
  .home-single .home-events .event-item {
    width: auto;
    float: none;
    height: auto; }
    .home-single .home-events .event-item.has-thumb .event-img {
      float: none;
      width: auto; }
    .home-single .home-events .event-item.has-thumb .event-item-entry {
      float: none;
      height: auto;
      overflow-y: visible;
      width: auto; }
      .home-single .home-events .event-item.has-thumb .event-item-entry .event-excerpt {
        max-height: 110px;
        margin-bottom: 90px; }
    .home-single .home-events .event-item.event-item-info-block {
      padding-top: 20px;
      padding-bottom: 20px; }
  .home-single .make-donation-section {
    padding: 20px 0; }
    .home-single .make-donation-section .donation-title {
      text-align: center; }
    .home-single .make-donation-section .site-name {
      text-align: center; }
  .home-single .gallery-section {
    position: relative; }
    .home-single .gallery-section .gallery-left {
      width: 100%;
      float: none;
      height: 450px; }
    .home-single .gallery-section .gallery-right {
      width: 50%;
      height: 50%;
      float: none;
      position: absolute;
      top: 0;
      left: 0; }
    .home-single .gallery-section .gallery-left-small-nav .gallery-title {
      margin-top: 10px; }
    .home-single .gallery-section .gallery-left-small-nav .gallery-link {
      padding: 20px; }
  .home-single .subscribe-section .subscribe-text-wrap {
    border: none;
    padding: 0;
    text-align: center; }
  .home-single .blog-section .blog-item {
    height: auto; }
    .home-single .blog-section .blog-item.has-thumb .blog-img,
    .home-single .blog-section .blog-item.has-thumb .blog-entry {
      width: 100%;
      float: none;
      height: auto;
      overflow: auto; }
    .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap {
      width: 100%;
      float: none;
      height: auto;
      padding: 30px; }
    .home-single .blog-section .blog-item .blog-content {
      max-height: none;
      overflow: visible;
      margin-bottom: 90px; }
  .home-single .about-us-section .about-content-top .site-logo {
    margin: 30px;
    margin-left: 0; }
    .home-single .about-us-section .about-content-top .site-logo .site-title {
      width: 70px;
      margin-left: 170px;
      font-size: 20px;
      margin-top: 40px; }
  .home-single .about-us-section .about-content-top .join-text {
    margin-top: 0; }
  .home-single .about-us-section .about-content-btm {
    height: auto;
    padding-bottom: 15px; }
    .home-single .about-us-section .about-content-btm .about-text {
      margin-left: 0;
      margin-right: 0; }
    .home-single .about-us-section .about-content-btm .about-link {
      margin-left: 0; }
  .home-single .video-section .video-entry {
    padding-right: 0;
    padding-bottom: 15px; }
  .home-single .footer-section {
    background: #ebf5f8; }
    .home-single .footer-section .form-send-msg-wrap {
      padding: 0; }
    .home-single .footer-section .social-footer-block {
      padding-top: 20px;
      padding-bottom: 20px;
      height: auto; }
    .home-single .footer-section .msg-row textarea {
      max-height: none;
      resize: vertical; }
  .about-page .contacts-wrap {
    padding: 15px;
    text-align: center; }
  .about-page .entry {
    padding: 30px; }
  .about-page .full-img-text {
    margin-top: 0;
    padding-right: 0;
    padding-bottom: 30px; }
  .about-page .waiting-for-you-wrap {
    text-align: center;
    margin: 0; }
  .mission-page .page-title {
    min-height: 0; }
    .mission-page .page-title h1 {
      transform: none; }
  .mission-page .entry {
    padding: 30px; }
  .mission-page .short-entry {
    padding-top: 20px; }
  .mission-page .col-img {
    padding-left: 15px;
    padding-right: 15px; }
    .mission-page .col-img img {
      width: 100%; }
  .mission-page .waiting-for-you-wrap {
    text-align: center;
    margin: 0; }
  #main-nav .main-mob-menu {
    margin-top: 40px;
    text-align: right;
    padding-right: 30px;
    font-size: 20px; }
  .home-single .subscribe-section .wpcf7-form {
    text-align: center; }
  .home-single .subscribe-section .subscribe-form-wrap {
    padding-left: 0; }
  .home-single .subscribe-section .subscribe-text-wrap {
    padding-right: 0; }
  .home-single .footer-section .name-row input {
    width: 100%; }
  .home-single .footer-section .msg-email {
    margin-top: 15px; }
  .home-single .footer-section .msg-row textarea {
    max-width: none;
    width: 100%; }
  .home-single .footer-section .msg-row .submit-main-form {
    width: 100%;
    max-width: none;
    margin-top: 15px; }
  .directors-fields {
    padding: 15px; }
  .home-single .home-events .event-item.has-thumb.event-item-3 .event-img {
    float: none; }
  .home-single .subscribe-section .subscribe-email {
    margin-right: 0; }
  .random-photos-wrap {
    display: none; } }

@media (max-width: 425px) {
  .amazon-section .amazon-link a {
    display: block; }
  #main-nav .main-mob-menu li {
    margin-bottom: 10px; }
  .top-header-wrap .header-elements .donate {
    padding-right: 5px; }
  .top-header-wrap .mobile-site-logo {
    padding-left: 5px; }
  .home-single .footer-section .social-footer-block .come-join {
    font-size: 28px; }
  .home-single .mission-block-wrap {
    left: 10px;
    right: 10px;
    width: auto;
    max-width: none; }
  .home-single .home-events .event-item-info-block .event-link-block-title {
    font-size: 28px; }
  .home-single .home-events .event-item.event-item-info-block {
    padding: 30px; }
  .home-single .make-donation-section .donate-link a {
    width: 100%;
    text-align: center; }
  .home-single .subscribe-section .subscribe-email {
    max-width: none;
    width: 100%; }
  .home-single .subscribe-section .subscribe-submit {
    width: 100%;
    margin-top: 20px; }
  .home-single .subscribe-section .subscribe-form-wrap {
    padding-left: 0; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    font-size: 32px;
    padding-left: 5px; }
  .home-single .gallery-section .gallery-left-small-nav .gallery-link {
    padding: 10px; }
    .home-single .gallery-section .gallery-left-small-nav .gallery-link a {
      font-size: 9px; } }
