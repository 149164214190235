@media (max-width: 1200px) {
  .top-header-wrap {
    .social-links-wrap {
      padding-top: 24px;
      padding-left: 10px;
    }
    .social-links {
      li {
        margin-right: 5px;
        a {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
        }
      }
    }
  }

  .site-logo {
    .site-title {
      font-size: 16px;
      margin-left: 148px;
    }
  }
  .home-single .gallery-section .gallery-left-small-first-bg .text-second {
    font-size: 28px;
  }
  .home-single .about-us-section .about-content-top {
    background: #fff;
    //.site-logo {
    //  margin: 72px 50px 42px 32px;
    //  .site-title {
    //    margin-left: 130px;
    //    padding-top: 5px;
    //  }
    //}
  }

  /*--------------------------------------------------------------
  ## Donate page
  --------------------------------------------------------------*/
  .donation-page {
    .donate-form {
      .donate-fields {
        .form-label {
          display: block;
          margin-bottom: 20px;
        }
        .tCell {
          padding-bottom: 10px;
        }
      }
    }
  }

  .organizers-wrap {
    .organizer-info {
      padding: 0;
      .organizer-name {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  .sidebar-event-wrap {
    font-size: 12px;
  }

  .home-single {
    .home-events {
      .event-item-info-block {
        padding-top: 50px;
      }
    }

    .make-donation-section {
      .donation-title {
        font-size: 32px;
      }
      .donate-text {
        padding-left: 15px;
      }
    }

    .gallery-section {
      .gallery-left-small-nav {
        .gallery-nav .left-slide,
        .gallery-nav .right-slide {
          width: 70px;
          height: 70px;
          .fa {
            font-size: 70px;
            //line-height: 70px;
          }
        }
        .gallery-title {
          font-size: 62px;
          margin-bottom: 10px;
        }
      }
    }

    .subscribe-section {
      .subscribe-site {
        font-size: 24px;
      }
      .subscribe-text-1 {
        font-size: 24px;
      }
    }

    .blog-section {
      .blog-item.blog-item-3 {
        .blog-more-wrap {
          padding-top: 30px;
        }
      }
    }

    .about-us-section {
      .about-content-btm,
      .about-side-btm {
        height: 250px;
      }

    }
    .blog-section {
      .blog-section-title {
        font-size: 52px;
        margin-left: 20px;
      }

    }
  }

  .about-page,
  .mission-page {
    .waiting-for-you-wrap {
      padding: 25px;
      .waiting-text {
        font-size: 30px;
      }
      .social-block {
        .social-links li a {
          margin-right: 4px;
        }
      }
    }
  }
  .home-single .blog-section .blog-item.blog-item-3 .blog-more-wrap .link-title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .top-header-wrap {
    .social-links-wrap {
      padding-top: 30px;
      padding-left: 10px;
    }
    .social-links {
      li {
        a {
          width: 30px;
          height: 30px;
          line-height: 30px;
          font-size: 16px;
        }
        margin-right: 5px;
      }
    }
  }
  .site-logo {
    .site-title {
      padding-top: 0;
      margin-left: 110px;
    }
  }
  .home-single .gallery-section .gallery-left-small-first-bg .text-second {
    font-size: 20px;
    line-height: 1.5;
  }

  .home-single {
    .make-donation-section {
      .site-name {
        text-align: center;
      }
      .donation-title {
        text-align: center;
      }
      .donate-link {
        text-align: center;
        margin-top: 0;
      }
      .donate-text {
        text-align: center;
        padding-left: 0;
      }
    }

  }
  /*--------------------------------------------------------------
  ## Common styles
  --------------------------------------------------------------*/
  .gray-text-block {
    font-size: 18px;
    line-height: 22px;
  }
  .sidebar-blog-wrap ul li a {
    font-size: 16px;
  }

  .common-single {
    .post-date .month-year,
    .post-date .weekday {
      font-size: 14px;
    }
    .post-date .day {
      margin-right: 0;
    }
    .single-content-wrap {
      padding: 30px;
    }
    .page-title {
      h1 {
        font-size: 24px;
      }
    }
    .event-program,
    .order-ticket-form-wrap {
      .c-table,
      .c-row,
      .c-cell {
        display: block;
      }
    }
    .order-ticket-form-wrap {
      input {
        margin-bottom: 15px;
        display: block;
      }
    }
  }
  /*--------------------------------------------------------------
  ## Blog list page
  --------------------------------------------------------------*/
  .blog-list {
    .blog-date {
      margin-top: 15px;
    }
    .blog-excerpt {
      margin-bottom: 15px;
    }
  }
  /*--------------------------------------------------------------
  ## Donate page
  --------------------------------------------------------------*/
  .donation-page {
    .donate-form {
      .amount-title {
        display: inline-block;
        float: left;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;

      }
      .paypal-img-mobile {
        display: inline-block;
        float: right;
      }
      .paypal-img {
        display: none;
      }
      .donate-fields {
        .tCell {
          width: 100%;
        }
        .form-label {
          margin-bottom: 5px;
        }
      }
      input,
      select {
        width: 100%;
      }
      .all-amount {
        margin-bottom: 10px;
      }
    }
  }

  .home-single {
    .mobile-head {
      height: 400px;
    }
    .col-content {
      height: 400px;
    }
    .single-sidebar-wrap {
      height: 400px;
    }
    .slider-wrap {
      height: 400px;
      .top-slider {
        height: 400px;
      }
      .owl-item {
        height: 400px;
      }
      .slide-top-item {
        height: 400px;
      }
    }

    .mission-block-wrap {
      padding: 15px 15px 55px;
      bottom: 60px;
      left: -120px;
      .mission-text {
        font-size: 16px;
      }
    }

    .owl-carousel {
      .owl-item {
        img {
          min-height: 100%;
          min-width: 100%;
        }
      }
    }

    .events-content-title {
      background: $lightBlue;
      padding: 10px 30px;
      border-top: 8px solid $activeColor;
    }
    .section-title-wrap {
      .section-title {
        transform: none;
        color: #a3bec9;
      }
    }

    .make-donation-section {
      .site-name {
        font-size: 20px;
      }
    }
    .gallery-section .gallery-left-small-nav {
      .gallery-title {
        transform: none;
        position: relative;
        font-size: 42px;
        text-align: center;
        margin-top: 40px;
      }
      .gallery-link {
        padding: 40px 20px;
        right: 0;
        text-align: center;
      }
    }
    .subscribe-section {
      .subscribe-form-wrap {
        padding-left: 10px;
      }
      .subscribe-email {
        max-width: 180px;
      }
      .subscribe-submit {
        padding: 0 30px;
      }
      .wpcf7-response-output {
        font-size: 24px;
        line-height: 1;
      }
    }
    .blog-section {
      .blog-item {
        .blog-entry {
          padding: 25px 30px;
        }

        &.blog-item-3 .blog-more-wrap {
          .link-title {
            //font-size: 38px;
            margin-bottom: 15px;
            line-height: 1.5;
          }
          .site-name {
            font-size: 18px;
          }
        }

      }

      .blog-section-title-wrap {

        //margin: 15px;
      }
      .blog-section-title {
        transform: none;
        border-bottom: 8px solid $activeColor;
        //background: $grayBlue;
        //color: #fff;
        margin: 0;
        line-height: 1;
        padding: 20px 30px;
      }
    }
    .about-us-section {
      .about-side-top {
        background: $lightBlue;
        border-bottom: 8px solid $activeColor;
        .about-title {
          transform: none;
          line-height: 1;
          margin: 0;
          padding: 20px 30px;
          color: #a3bec9;
        }
      }
      .about-content-top {
        .site-logo {
          margin-right: 70px;
          //margin-top: 0;
          .site-title {
            margin-left: 130px;
            margin-top: 30px;
          }
        }
      }
      .about-side-btm {
        display: none;
      }
      .about-content-btm {
        .about-text {
          margin-left: 30px;
          margin-right: 30px;
          //margin-top: 60px;
        }
      }
    }
    .footer-section {
      .form-title {
        margin: 0;
        padding: 30px;
      }
      .form-send-msg-wrap {
        background: $lightBlue;
      }
      .form-send-msg-wrap {
        //padding-bottom: 30px;
        padding: 30px;
      }
    }
  }
  .about-page,
  .mission-page {
    .site-logo-wrap-col {
      display: none;
    }
  }
  .mission-page {
    .img-section {
      .blue-square {
        display: none;
      }
      .img-text {
        padding-right: 0;
        background: #fff;
        padding-bottom: 40px;
      }
    }
  }
  .directors-fields {
    .director-img {
      margin-bottom: 30px;
    }
  }

  .amazon-section {
    text-align: center;
    .amazon-link {
      //text-align: center;
    }
    .amazon-text {
      margin-top: 20px;
      //text-align: center;
    }
  }

  .random-photos-wrap {
    margin-right: 0;
    .galleries-link {
      font-size: 12px;
    }
    .photos-title {
      font-size: 14px;
    }
  }
  .sidebar-blog-wrap {
    padding: 60px 0 0;
    .blog-page-link a {
      font-size: 12px;
    }
  }
  .sidebar-event-wrap {
    padding: 0;
    .event-page-link a {
      font-size: 12px;
    }
  }
  .common-single {
    .order-link-side {
      font-size: 12px;
    }
    .post-date {
      .day {
        font-size: 72px;
      }
      .additional-date {
        padding-top: 10px;
      }
    }

  }

}

@media (max-width: 767px) {
  .top-header-wrap {
    background: #fff;
    .social-links-wrap {
      display: none;
    }
    .header-elements {
      height: 88px;
      text-align: left;
      .donate {
        padding-right: 10px;
        .btn-blue {
          width: 50px;
          padding: 0;
        }
        .donate-icon {
          display: block;
          width: 30px;
          margin-left: 10px;
          height: 50px;
        }
        .donate-text {
          display: none;
        }
      }
      .lang-switch {
        display: none;
      }
      .menu-btn-wrap {
        float: right;
        padding-top: 12px;
        padding-left: 0;
        .menu-text {
          display: none;
        }
      }
    }
    .mobile-site-logo {
      display: inline-block;
      width: 170px;
      position: relative;
      text-align: left;
      padding-left: 10px;
      .site-title {
        text-align: left;
        display: inline-block;
        font-size: 16px;

        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding-left: 65px;
        font-family: $openSansCondensed;
        font-weight: 500;
        padding-top: 10px;
        a {
          color: #555;
        }
      }
    }
  }
  .mobile-head .single-sidebar-wrap {
    padding-bottom: 5px;
    border-bottom: 7px solid #68b8fa;
  }
  .page-title-wrap {
    float: right;
  }
  .post-date {
    float: left;
    //padding-right: 15px;
  }
  .site-logo-wrap {
    display: none;
  }
  .sidebar-blog-wrap {
    display: none;
  }
  .white-blue-bg {
    background: none;
  }
  .sintetic-bg-lblue {
    display: none !important;
  }

  .common-single {
    .post-date {
      .day {
        font-size: 80px;
      }
      .additional-date {
        padding-top: 20px;
      }
      .calendar-icon {
        margin-bottom: 3px;
      }
    }
    .page-title {
      padding-right: 15px;
      padding-top: 20px;
    }

    .single-content-wrap {
      padding: 15px 30px;
    }
    .order-link-side {
      right: 0;
      margin-right: 30px;
    }
    .event-program {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .blog-list {
    .page-title-wrap {
      width: 30%;
      text-align: right;
      .page-title {
        padding-top: 0;
      }
    }
    .page-description {
      width: 70%;
    }
    .blog-list-wrap {
      padding: 15px 30px;
    }
  }
  /*--------------------------------------------------------------
  ## Donate page
  --------------------------------------------------------------*/
  .donation-page,
  .default-page {
    background: none;
    .page-title {
      text-align: left;
      padding-bottom: 12px;
    }
    .mobile-head {
      background: $lightBlue;
      border-bottom: 7px solid #68b8fa;
    }
    .site-logo-wrap {
      padding-top: 0;
    }
    .site-logo {
      display: none;
    }
    .page-title {
      transform: none;
    }
    .donate-sidebar-wrap,
    .default-sidebar-wrap {
      min-height: 10px;
    }
    .donate-form-wrap {
      padding: 0;
      padding-top: 60px;
    }
    .donate-entry,
    .default-entry {
      padding: 25px 10px;
    }
    .donate-other {
      padding: 25px 10px;
    }
  }

  .donation-page .form-title {
    margin-left: 30px;
  }

  .site-footer {
    .footer-right,
    .footer-left {
      text-align: center;
    }
    .footer-left {
      margin-bottom: 15px;
    }
  }

  .sidebar-event-wrap {
    display: none;
  }

  .event-single .order-ticket-form-wrap {
    padding: 30px;
  }
  .slogan-wrap {
    display: none;
  }

  #main-nav {
    ul.lang-switch,
    .social-links-wrap {
      display: block;
    }
  }

  .home-single {
    .mobile-head {
      display: none;
    }
    .mission-block-wrap {
      max-width: 90%;
      left: 40px;
      text-align: center;
      padding: 15px;
      .mission-text:after {
        display: none;
      }
      .mission-link {
        position: relative;
        margin: 0;
        padding: 20px 0;
        a {
          width: 100%;
        }
      }
    }
    .home-events {
      .event-item {
        width: auto;
        float: none;
        height: auto;
        &.has-thumb {
          .event-img {
            float: none;
            width: auto;
          }
          .event-item-entry {
            float: none;
            height: auto;
            overflow-y: visible;
            width: auto;
            .event-excerpt {
              max-height: 110px;
              margin-bottom: 90px;
            }
          }
        }
        &.event-item-info-block {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    .make-donation-section {
      padding: 20px 0;
      .donation-title {
        text-align: center;
      }
      .site-name {
        text-align: center;
      }
    }
    .gallery-section {
      position: relative;
      .gallery-left {
        width: 100%;
        float: none;
        height: 450px;
      }
      .gallery-right {
        width: 50%;
        height: 50%;
        float: none;
        position: absolute;
        top: 0;
        left: 0;
      }
      .gallery-left-small-nav {
        .gallery-title {
          margin-top: 10px;
        }
        .gallery-link {
          padding: 20px;
        }
      }
    }
    .subscribe-section {
      .subscribe-text-wrap {
        border: none;
        padding: 0;
        text-align: center;
      }
    }

    .blog-section {
      .blog-item {
        height: auto;
        &.has-thumb {
          .blog-img,
          .blog-entry {
            width: 100%;
            float: none;
            height: auto;
            overflow: auto;
          }
        }
        &.blog-item-3 {
          .blog-more-wrap {
            width: 100%;
            float: none;
            height: auto;
            padding: 30px;
          }
        }
        .blog-content {
          max-height: none;
          overflow: visible;
          margin-bottom: 90px;
        }
      }
    }

    .about-us-section {
      .about-content-top {
        .site-logo {
          margin: 30px;
          margin-left: 0;
          .site-title {
            width: 70px;
            margin-left: 170px;
            font-size: 20px;
            margin-top: 40px;
          }
        }
        .join-text {
          margin-top: 0;
        }
      }
      .about-content-btm {
        height: auto;
        padding-bottom: 15px;
        .about-text {
          margin-left: 0;
          margin-right: 0;
        }
        .about-link {
          margin-left: 0;
          a {
          }
        }
      }
    }

    .video-section {
      .video-entry {
        padding-right: 0;
        padding-bottom: 15px;
      }
    }

    .footer-section {
      background: $lightBlue;
      .form-send-msg-wrap {
        padding: 0;
      }
      .social-footer-block {
        padding-top: 20px;
        padding-bottom: 20px;
        height: auto;
      }
      .msg-row textarea {
        max-height: none;
        resize: vertical;
      }
    }
  }
  .about-page {
    .contacts-wrap {
      padding: 15px;
      text-align: center;
    }
    .entry {
      padding: 30px;
    }
    .full-img-text {
      margin-top: 0;
      padding-right: 0;
      padding-bottom: 30px;
      &:before {
        //display: none;
      }
    }
    .waiting-for-you-wrap {
      text-align: center;
      margin: 0;
    }
  }
  .mission-page {
    .page-title {
      min-height: 0;
      h1 {
        transform: none;
      }
    }
    .entry {
      padding: 30px;
    }
    .short-entry {
      padding-top: 20px;
    }
    .col-img {
      padding-left: 15px;
      padding-right: 15px;
      img {
        width: 100%;
      }
    }
    .waiting-for-you-wrap {
      text-align: center;
      margin: 0;
    }
  }
  #main-nav .main-mob-menu {
    margin-top: 40px;
    text-align: right;
    padding-right: 30px;
    font-size: 20px;
  }
  .home-single .subscribe-section .wpcf7-form {
    text-align: center;
  }
  .home-single .subscribe-section .subscribe-form-wrap {
    padding-left: 0;
  }
  .home-single .subscribe-section .subscribe-text-wrap {
    padding-right: 0;
  }

  .home-single .footer-section {
    .name-row input {
      width: 100%;
    }
    .msg-email {
      margin-top: 15px;
    }
    .msg-row {
      textarea {
        max-width: none;
        width: 100%;
      }
      .submit-main-form {
        width: 100%;
        max-width: none;
        margin-top: 15px;
      }
    }
  }
  .directors-fields {
    padding: 15px;
  }

  .home-single .home-events .event-item.has-thumb.event-item-3 .event-img {
    float: none;
  }

  .home-single .subscribe-section .subscribe-email {
    margin-right: 0;
  }

  .random-photos-wrap {
    display:none;
  }
}

@media (max-width: 425px) {
  .amazon-section .amazon-link a {
    display: block;
  }
  #main-nav .main-mob-menu {
    li {
      margin-bottom: 10px;
    }
  }
  .top-header-wrap {
    .header-elements .donate {
      padding-right: 5px;
    }
    .mobile-site-logo {
      padding-left: 5px;
    }

  }

  .home-single .footer-section .social-footer-block .come-join {
    font-size: 28px;
  }
  .home-single {
    .mission-block-wrap {
      left: 10px;
      right: 10px;
      width: auto;
      max-width: none;
    }
    .home-events .event-item-info-block .event-link-block-title {
      font-size: 28px;
    }
    .home-events .event-item.event-item-info-block {
      padding: 30px;
    }
    .make-donation-section {
      .donate-link {
        a {
          width: 100%;
          text-align: center;
        }
      }
    }

    .subscribe-section {
      .subscribe-email {
        max-width: none;
        width: 100%;
      }
      .subscribe-submit {
        width: 100%;
        margin-top: 20px;
      }
      .subscribe-form-wrap {
        padding-left: 0;
      }
    }
  }
  .home-single .gallery-section .gallery-left-small-nav .gallery-title {
    font-size: 32px;
    padding-left: 5px;
  }
  .home-single .gallery-section .gallery-left-small-nav .gallery-link {
    padding: 10px;
    a {
      font-size: 9px;
      //padding: 0 10px;
    }
  }
}
